<template>
    <div>
        <van-popover v-model="showPopover" trigger="click" class="popover">
            <div class="popover_box">
                <div class="popover_box_item" @click="selectLang(item)" v-for="(item,index) in NetWorkList" :key="index">
                    <img :src="item.img" alt="">
                    <span>{{ item.name }}</span>
                </div>
           
              
            </div>
            <template #reference>
                <div class="addressBox">
                    <img style="width: 10px; margin-right: 5px; height: 10px" :src="NetItemData.image"
                            alt="" />

                      <span> {{ NetItemData.name }}</span>  
                </div>
            </template>
        </van-popover>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    data() {
        return {
            showPopover: false,
            NetItemData:{},
            NetWorkList:[
                {
                    image:require('@/assets/img/bsc_icon1.png'),
                    img:require('@/assets/img/logo/BNB.png'),
                    name:'BSC', 
                    id:56
                },
                {
                    image:require('@/assets/img/KAD_icon.png'),
                    img:require('@/assets/img/logo/KAD.png'),
                    name:'KAD', 
                    id:878
                }
            ]
        }
    },
    components: {

    },
    computed: {
    ...mapState(['NetItem']),
  },
  created(){
        this.NetItemData =  this.NetItem 
  },
    mounted() {
    
    },
    methods: {
        selectLang(item) {
            this.showPopover = false
           this.NetItemData = item
          
            this.$store.commit('SETNETITEM', item);
        }
    },
}

</script>
<style scoped lang='less'>
.language {
    width: 20px;
    height: 20px;
}
.addressBox {
    margin-left: 12px;
    padding: 2px 5px;
    border-radius: 44px;

    border: 1px solid var(--text-color);
    // padding: 5px;
    display: flex;
    align-items: center;
      font-size: 14px;
      color: #fff;
  }
.popover {
    // background-color: rgba(26, 31, 46, 1) !important;
}

.popover_box {
    padding: 10px;
    padding-bottom: 0;

    .popover_box_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        font-size: 14px;

        img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }
    }
}
</style>