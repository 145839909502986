import lpAbi from '../config/abi/lp.json'
import Vue from 'vue';
import Web3 from 'web3'
import BigNumber from 'bignumber.js'
import store from '../store';


function poolLength() {
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(lpAbi, '0xFFf8C5909d408Dc8a60aFF080a76BFffD989f435');
        crossingOkContractInstance.methods.poolLength().call().then(res => {
            resolve(res)
        })
    })
}

//授权
function deposit(myAddress, pid, amount,transactionsDetail) {
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(lpAbi, '0xFFf8C5909d408Dc8a60aFF080a76BFffD989f435');
        crossingOkContractInstance.methods.deposit(pid, amount).send({ from: myAddress })
        .on('transactionHash', res => {
            transactionsDetail['hash'] = res
            store.commit('SETTRANSACTIONS', transactionsDetail);
        }).on('confirmation', res => {
            resolve(res)
        }).on('error', res => {
            reject(res)
        })
    })
}


//获取个人信息
function userinfo(myaddress, pid) {
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(lpAbi, '0xFFf8C5909d408Dc8a60aFF080a76BFffD989f435');
        crossingOkContractInstance.methods.userInfo(pid, myaddress).call().then(res => {
            resolve(res)
        })
    })
}

//获得奖励
function pendingReward(pid, myAddress) {
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(lpAbi, '0xFFf8C5909d408Dc8a60aFF080a76BFffD989f435');
        crossingOkContractInstance.methods.pendingSwan(pid, myAddress).call().then(res => {
            resolve(res)
        })
    })
}

//取出
function withdraw(myAddress, pid, amount,transactionsDetail) {
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(lpAbi, '0xFFf8C5909d408Dc8a60aFF080a76BFffD989f435');
        crossingOkContractInstance.methods.withdraw(pid, amount).send({ from: myAddress })
        .on('transactionHash', res => {
            transactionsDetail['hash'] = res
            store.commit('SETTRANSACTIONS', transactionsDetail);
        }).on('confirmation', res => {
            resolve(res)
        }).on('error', res => {
            reject(res)
        })
    })
}

//领取奖励
function harvest(myAddress, pid,transactionsDetail) {
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(lpAbi, '0xFFf8C5909d408Dc8a60aFF080a76BFffD989f435');
        crossingOkContractInstance.methods.harvest(pid).send({ from: myAddress })
        .on('transactionHash', res => {
            transactionsDetail['hash'] = res
            store.commit('SETTRANSACTIONS', transactionsDetail);
        })
        .on('confirmation', res => {
            resolve(res)
        }).on('error', res => {
            reject(res)
        })
    })
}

function poolReward(){
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(lpAbi, '0xFFf8C5909d408Dc8a60aFF080a76BFffD989f435');
        crossingOkContractInstance.methods.poolsReward().call().then(res => {
            resolve(res)
        })
    })
}

export default {
    poolLength,
    deposit,
    userinfo,
    pendingReward,
    withdraw,
    harvest,
    poolReward
}