<template>
  <div class="chobi">
      <div class="chozhi">
          <div class="tonzhen" @click="tonzhenfunis">
              <span>{{stokenname}}</span>
              <img src="@/assets/imgsnowball/xiala.png" alt="">
          </div>
          <van-popup v-model="tonzhenshow" round position="bottom" class="vantonzhenshow">
                <div class="t_li" v-for="(item,index) in tokens" @click="seclettokenfun(index,item.token,item.token_id,item.fee_rate)">
                    <img :src="`${imgurl}${item.logo}`" alt="">
                    <span> {{ item.token }}</span>
                </div>
               
                <div class="quxiao">
                    <span @click="tonzhenfunis">{{$t('lang.swap154')}}</span>
                </div>
            </van-popup>
          <p class="c_shul">{{$t('lang.d33')}}</p>
          <van-field type="number"
                         v-model="toInput"
                         input-align="left"
                        
                         :placeholder="`${$t('lang.d34')} `"
                         class="van-field" />
                         <div class="line3">
                        
                          <p class="balance" v-show="yuen">{{$t('lang.d30')}}{{ balance }}</p>
                         </div>
         
          <p class="c_shul">{{$t('lang.d35')}}</p>
        
          <van-field type="text"
                         v-model="toaddress"
                         input-align="left"
                        
                         :placeholder="`${$t('lang.d36')} `"
                         class="van-field" />
                         <div v-show="yuen" style="font-size: 14px; margin-top: 10px;">
                          <div>{{$t('lang.d99') }}
                         </div>
                         <div>
                          {{ $t('lang.d111',{feerate:feerate*100})}}
                         </div>
                        </div>
                       
                         <div class="c_button" v-if="showbutton" @click="sendtranform">{{$t('lang.d26')}}</div>
          <div class=" zhihui" v-else>{{$t('lang.d26')}}</div>
      </div>
<p class="c_jilu">{{$t('lang.d37')}}</p>
 <div class="c_wlist" v-if="zhuanzhanglist.length  ==0">
    <img src="@/assets/imgsnowball/wujilu.png" alt="">
    <p>{{$t('lang.d57')}}</p>
 </div>
 <div class="c_list" v-else>
   <div class="li" v-for="item in zhuanzhanglist">
      <div>
          <div class="c_head">
            <span v-if="item.behavior ==0"> {{$t('lang.d84')}}</span>
           <span v-else-if="item.behavior ==1"> {{$t('lang.d85')}}</span>
            {{ item.token_name }}</div>
   <div> {{ item.created_time }}</div>
      </div>
   <span>＋{{ item.amount }}</span>
   </div>
  
 </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Web3 from 'web3';
import { getErc20Contract } from '@/utils/contractHelp';
import { zhuanzhang,bizhong,homedata,moneymask} from '@/api/index'
export default {
  data() {
      return {
          tonzhenshow: false,
          toInput:"",
          toaddress:"",
          zhuanzhanglist:[],
          stokenname:this.$t('lang.d21'),
          tokens:[],
          balance:"",
          imgurl:"",
          sindex:"",
          tokenId:"",
          feerate:0
      };
  },
  created() {
     this.init()
  },
  mounted() {
        console.log("edd",Boolean(this.zhuanzhanglist));
  },
  computed: {
    ...mapState(['account','lang']),
        showbutton (){
            return  this.stokenname !=this.$t('lang.d21')&&!!this.toInput&&!!this.toaddress;
        },
        yuen (){
          if(this.stokenname !=this.$t('lang.d21')){
              return true;
          }else{
            false
          }
      },
    },
    watch: {
    account () {
      this.init();
      // this.finished = false;
    },
    lang (){
      this.init();
      if(this.stokenname != this.$t('lang.d21')){
        this.stokenname =this.$t('lang.d21')
      }
    
    }
  },
  methods: {
      tonzhenfunis() {
          this.tonzhenshow = !this.tonzhenshow;
      },
       init (){
          this.getlist()
        
            this.getimgurl()
          
       },
     
        getimgurl(){
            homedata.gettokenimgurl().then(res =>{
                this.imgurl =res.data.image_domain
            })
        },
        async geterc20banceof(){
          let data ={
            token_id:this.tokenId
           };
           moneymask.getwalletgetinfo(data,this.account).then(res =>{
            this.balance =res.data.usable
           })
        },
       seclettokenfun(index,name ,id,fee){
    
            this.tonzhenshow =false;
            this.stokenname =name;
            this.tokenId = id;
            this.feerate =fee
            this.geterc20banceof()
        },
        async sendtranform (){
          if(this.toInput<=this.balance){
            if(this.toInput<=0){
                this.$toast(this.$t('lang.d79'));
            }else{
                this.sendf()
            }
              
        }else{
            this.$toast(this.$t('lang.d80'));
        }
      },
     async sendf(){
      let web3 = new Web3(window.ethereum);
            const sign = await web3.eth.personal.sign(web3.utils.fromUtf8('Transfer Out'), this.account)
            console.log("sign",sign);
         const parseinput =  parseFloat(this.toInput);
      let data={
      
        token_id:this.tokenId,
        amount: parseinput,
        to_address:this.toaddress,
        signature:sign
      }
        await zhuanzhang.posttransferout(data,this.account).then(res =>{
                    console.log(res);
                 
                      this.$toast(res.msg);
                   
         })
     },
      getlist (){
        zhuanzhang.gettransfercondition(this.account).then(res =>{
                console.log(res);
                this.tokens=res.data;
            })
           
        let data ={
          page :1,
          page_size:10
        }
            zhuanzhang.gettransferlist(data,this.account).then(res =>{
              this.zhuanzhanglist =res.data.list 
           console.log(res);
            })
      }
  },
};
</script>

<style lang="less" scoped>
.chobi {
  padding: 20px;
  width: 100%;
  background: rgba(16, 20, 32, 1);
  border-radius: 20px;
}

.chozhi {
  width: 100%;
  padding: 15px 20px;
     
  border-radius: 20px;
  background: rgba(25, 29, 41, 1);

  .tonzhen {
      width: 100%;
      height: 52px;
      border-radius: 10px;
      background: rgba(16, 20, 32, 1);
      padding: 15px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
          width: 10px;
          height: 10px;

      }

      span {
          color: #fff;
          font-size: 14px;
          font-weight: 700;
          font-family: "PingFang";
      }
  }

  .vantonzhenshow {
      padding: 20px;

      .t_li {
          height: 50px;
          width: 100%;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #0000000f;

          img {
              width: 24px;
              height: 24px;
              margin-right: 15px;
              border-radius: 50%;
          }
      }

      .quxiao {
          width: 100%;
          height: 50px;
          display: flex;
          justify-content: center;
          margin-top: 20px;
          color: #00000066;
          font-size: 16px;
          font-weight: 500;
          font-family: "PingFang SC";
      }
  }

  .c_shul {
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      font-family: "PingFang";
      margin-top: 30px;
  }

  .van-field {
      width: 100%;
      border-radius: 10px;
      font-weight: bold;
      background: rgba(16, 20, 32, 1) !important;
      padding: 16px 20px !important;

      /deep/ .van-field__control {
          font-size: 18px;
          color: #9395A4;
      }
  }

  .balance {
      color: #9395a4ff;
      font-size: 12px;
      font-weight: 700;
      font-family: "PingFang";
  }

  .ruzhang {
      position: relative;

      span {
          color: #000;
          font-size: 14px;
          font-weight: 700;
          font-family: "PingFang";
      }

      .r_line {
          height: 15px;
          width: 1px;
          background: #56A0B7;
          position: absolute;
          right: 60px;
          top: 20px;
      }

      img {
          width: 20px;
          height: 20px;
      }
  }

  .c_button {
      margin-top: 30px;
      width: 100%;
      height: 55px;
      border-radius: 10px;
      opacity: 1;
      background: rgba(178, 87, 247, 1);
      box-shadow: 0 20px 30px 0 #191c321a;
      text-align: center;
      line-height: 55px;
      color: #000;
      font-size: 16px;
      font-weight: 700;
      font-family: "PingFang";
  }
}

.c_jilu {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  font-family: "PingFang";
  margin-top: 30px;
}

.c_wlist {
  width: 100%;
  height: 219px;
  border-radius: 20px;
  opacity: 1;
  background: rgba(25, 29, 41, 1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
      width: 122px;
height: 92px;

  }

  p {
      color: #fff;
      font-size: 14px;
      font-weight: 700;
      font-family: "PingFang";
  }
}

.c_list {
  width: 100%;
  background: rgba(25, 29, 41, 1);
  padding: 15px 18px;
  border-radius: 20px;

  .li {
      height: 40px;
      width: 100%;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .c_head {
              color: #fff;
              font-size: 14px;
              font-weight: 700;
              font-family: "PingFang";
          }

          .c_time {
              color: #848484;
              font-size: 12px;
              font-weight: 500;
              font-family: "PingFang";
          }
      }

      span {
          color: #24E7A3;
          font-size: 14px;
          font-weight: 700;
          font-family: "PingFang";
      }
  }
}

.zhihui {
  margin-top: 30px;
  width: 100%;
  height: 55px;
  border-radius: 10px;
  opacity: 0.6;
 
  text-align: center;
  line-height: 55px;
  color: #000;
  font-size: 16px;
  font-weight: 700;
  font-family: "PingFang";
  background: rgba(178, 87, 247, 1);
}

.op {
  opacity: 0.4;
}</style>