const HOSTURLOBJECT = window.location
let configURL = {
	http_url:'',
	initveUrl:''
}

if(HOSTURLOBJECT.hostname == 'localhost'){
	 configURL = {
		  http_url:"https://kam-minner.com/api/",
		  initveUrl:"https://kam-minner.com"
	}
}else{
	configURL = {
		  http_url: HOSTURLOBJECT.origin +'/api',
		  initveUrl:HOSTURLOBJECT.origin
	}
}
console.log('configURL',HOSTURLOBJECT);

export default configURL