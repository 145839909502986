<template>
    <div>
        <van-popover v-model="showPopover" trigger="click" class="popover" >
            <div class="popover_box">
                <div class="popover_box_item" @click="selectLang('zh-CN')">
                    <img src="@/assets/img/zh.png" alt="">
                    <span>中文简体</span>
                </div>
                <div class="popover_box_item" @click="selectLang('en-US')">
                    <img src="@/assets/img/en.png" alt="">
                    <span>English</span>
                </div>
                <div class="popover_box_item" @click="selectLang('zh-HK')">
                    <img src="@/assets/img/zh.png" alt="">
                    <span>中文繁体</span>
                </div>
                <div class="popover_box_item" @click="selectLang('vi-VN')">
                    <img src="@/assets/img/vi.png" alt="">
                    <span>Tiếng Việt</span>
                </div>
            </div>
            <template #reference>
                <img class="language" src="@/assets/img/lang_icon.png" />
            </template>
        </van-popover>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showPopover: false
        }
    },
    components: {

    },
    mounted() {

    },
    methods: {
           selectLang(lang){
            this.showPopover = false
            this.$i18n.locale = lang;
      localStorage.setItem('lang', lang);
      this.$store.commit('SETLANG', lang);
           }
    },
}

</script>
<style scoped lang='less'>


.language {
    width: 20px;
    height: 20px;
}
.popover{
    // background-color: rgba(26, 31, 46, 1) !important;
}

.popover_box {
    padding: 10px;
    padding-bottom: 0;

    .popover_box_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        font-size: 14px;

        img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }
    }
}
</style>