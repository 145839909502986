<template>
  <div class="nftcenter">
    <van-popup v-model:show="contentshow" round closeable>
      <div class="contentshow">
        <div class="contentshow_title">
          <span>{{ $t('lang.s152') }}</span>
        </div>
        <div class="contentshow_box">
          <p style="text-align: left">
            <span>{{ $t('lang.s153') }}</span>
          </p>

        </div>
      </div>
    </van-popup>
    <div class="recruit_title">
      <div style="transform: rotate(90deg); color: #fff" @click="toprouter"><van-icon name="down" /></div>
      <div style="font-size: 18px; font-weight: bold; margin-left: 20px"></div>
      <div></div>
    </div>
    <div class="nftcenter_sum" v-loading="loading">
      <div class="flex" style="align-items: start">
        <div class="sumbox">
          <div class="sumbox_title">{{ $t('lang.s154') }}</div>
          <div class="sumbox_num"><span>{{ hold_summary.valid_cp }}</span> <span style="font-size: 12px">T</span></div>
        </div>
        <img src="@/assets/imgsnowball/sumbox_img.png" class="sumbox_img" alt="" />
      </div>
      <div class="flex">
        <div class="flex" style="transform: scale(0.8) translateX(-30px)">
          <div class="sumbox" style="margin-right: 23px">
            <div class="sumbox_title">{{ $t('lang.s102') }}</div>
            <div class="sumbox_num"><span>{{ hold_summary.cp_reward }}</span> <span style="font-size: 12px">KAM</span>
            </div>
          </div>
          <div class="sumbox">
            <div class="sumbox_title">{{ $t('lang.s155') }}</div>
            <div class="sumbox_num"><span>{{ hold_summary.community_reward }}</span> <span
                style="font-size: 12px">KAM</span></div>
          </div>
        </div>
        <div class="button" @click="gopath('nftbill')">{{ $t('lang.s104') }}</div>
      </div>
    </div>

    <div class="minboxs">
      <div class="minbox">
        <img src="@/assets/imgsnowball/min_icon1.png" alt="">
        <span>{{ $t('lang.s156') }}：{{ hold_summary.total_cp }}</span>
      </div>
      <div class="minbox">
        <img src="@/assets/imgsnowball/min_icon2.png" alt="">
        <span>{{ $t('lang.s157') }}：{{ hold_summary.total_share_cp }}</span>
      </div>
    </div>
    <div class="lookboxs">
      <div class="lookbox">
        <div class="abs_look" @click="gopath('user')">{{ $t('lang.s105') }}</div>
        <div class="box">
          <div class="box_num">{{ summaryinfo.dr_valid_num }}</div>
          <img src="@/assets/imgsnowball/box1.png" alt="" />
          <div class="box_title">{{ $t('lang.s158') }}</div>
        </div>
      </div>
      <div class="lookbox">
        <div class="abs_look" style="background: rgba(117, 207, 95, 1);" @click="gopath('engbill', 0)">{{
          $t('lang.s105')
        }}</div>
        <div class="box" style="background: linear-gradient(0deg, rgba(64, 169, 255, 0.5) 0%, rgba(13, 8, 26, 0) 100%)">
          <div class="box_num">{{ summaryinfo.max_zone_cp }}</div>
          <img src="@/assets/imgsnowball/box2.png" alt="" />
          <div class="box_title">{{ $t('lang.s159') }}</div>
        </div>
      </div>
      <div class="lookbox">
        <div class="abs_look" style="background: rgba(56, 209, 207, 1);" @click="gopath('engbill', 1)">{{
          $t('lang.s105')
        }}</div>
        <div class="box"
          style="background: linear-gradient(0deg, rgba(254, 219, 147, 0.43) 0%, rgba(13, 8, 26, 0) 100%)">
          <div class="box_num">{{ summaryinfo.other_zone_cp }}</div>
          <img src="@/assets/imgsnowball/box3.png" alt="" />
          <div class="box_title">{{ $t('lang.s160') }}</div>
        </div>
      </div>
    </div>
    <div class="changeMingbox" v-loading="cproundsInfo.loading" v-if="cproundsInfo.round_list.length <= 0">
      <div class="changeMingbox_line">
        <div>{{ $t('lang.s161') }}</div>
        <div
          style="width: 53px;
height: 26px;
border-radius: 7px;
background: rgba(178, 87, 247, 0.1); display: flex; align-items: center; justify-content: center; font-size: 12px; color: rgba(178, 87, 247, 1);"
          @click="contentshow = true">{{ $t('lang.s152') }}</div>
      </div>
      <div class="changeMingbox_line" style="margin-bottom: 5px;">
        <van-slider style="border: 1px solid rgba(254, 219, 147, 0.43); padding: 1px;" bar-height="8" v-model="value"
          active-color="rgba(254, 219, 147, 1)" inactive-color="none" step="0.5" min="1" max="10"
          @drag-start="InputValueFun" @drag-end="changeValueFun">
          <template #button>
            <div class="custom-button">
              <div class="custom_abs" v-show="customabsBoolean">{{ value }} T</div>
            </div>
          </template>
        </van-slider>
      </div>
      <div class="changeMingbox_line" style="font-size: 12px; color: rgba(153, 153, 153, 1);">
        <div>1T</div>
        <div>10T</div>
      </div>
      <div class="changeMingbox_line">
        <div>{{ $t('lang.s162') }}</div>
      </div>
      <div class="paypop_checks_box">
        <span>XX KAD ＋ XX KAM</span>
        <div class="checkbox">
          <img src="@/assets/imgsnowball/check_false.png" alt="">
        </div>
      </div>

      <div class="button">
        {{ $t('lang.s163') }}
      </div>
    </div>
    <van-swipe class="" :loop="false" indicator-color="white" :show-indicators="false" @change="swipechange"
      ref="swiperRef" v-else>
      <van-swipe-item class="recruit_boxs" v-for="(item, index) in cproundsInfo.round_list" :key="index">
        <div class="changeMingbox">
          <div class="changeMingbox_line">
            <div> {{ $t('lang.s161') }}</div>
            <div
              style="width: 53px;
height: 26px;
border-radius: 7px;
background: rgba(178, 87, 247, 0.1); display: flex; align-items: center; justify-content: center; font-size: 12px; color: rgba(178, 87, 247, 1);"
              @click="contentshow = true">{{ $t('lang.s152') }}</div>
          </div>
          <div class="changeMingbox_line" style="margin-bottom: 5px;">
            <van-slider style="border: 1px solid rgba(254, 219, 147, 0.43); padding: 1px;" bar-height="8"
              v-model="item.check_num" active-color="rgba(254, 219, 147, 1)" inactive-color="none" step="0.5"
              :min="item.min_buy" :max="item.max_buy" @drag-start="InputValueFun" @drag-end="changeValueFun"
              @change="changeFun(item)">
              <template #button>
                <div class="custom-button">
                  <div class="custom_abs" v-show="customabsBoolean">{{ item.check_num }} T</div>
                </div>
              </template>
            </van-slider>
          </div>
          <div class="changeMingbox_line" style="font-size: 12px; color: rgba(153, 153, 153, 1);">
            <div>{{ item.min_buy }}T</div>
            <div>{{ item.max_buy }}T</div>
          </div>
          <div class="changeMingbox_line">
            <div>{{ $t('lang.s162') }}</div>
          </div>
          <div class="paypop_checks_box" @click="selectPayFun(payitem)"
            v-for="(payitem, payindex) in cproundsInfo.pay_list">
            <span>{{ payNumRatePrice(payitem, item, 't1') }}</span>+<span>{{ payNumRatePrice(payitem, item, 't2') }}</span>
            <div class="checkbox">
              <img src="@/assets/imgsnowball/check_true.png" alt="" v-if="selectPayData.id === payitem.id">
              <img src="@/assets/imgsnowball/check_false.png" alt="" v-else>
            </div>
          </div>

          <div class="button" @click="paypopFun(selectPayData,item)" v-loading="payLoading"  v-if="item.can_buy">
            {{ $t('lang.s163') }}
          </div>
          <div class="button" v-else style="opacity: 0.6;">
            {{ $t('lang.s164') }}
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>

    <div class="functional_region" id="myElementId">
      <div class="region">
        <div v-for="(item, index) of functionOption" :key="index" @click="switchovers(index)"
          :class="index == regionIndex ? 'special' : ''">
          <span>{{ $t(item.name) }}</span>
        </div>
      </div>
    </div>
    <div class="nftlist" v-show="regionIndex == 0">

      <van-list v-model="holdlistall.loading" :finished="holdlistall.finished" :finished-text="`${$t('lang.d145')}`"
        @load="onLoadholdall">
        <div class="nftlist_li" v-for="(item, index) in holdlistall.list" :key="index" style="padding-top: 11px">
          <div class="nftlist_li_cont">
            <img src="@/assets/imgsnowball/mining_icon.png" alt="" class="li_img" />
            <div class="li_right" style="flex: 1">
              <div class="li_right_name">{{ $t('lang.s111') }}{{ item.cp }} T</div>
              <div class="li_right_name"
                style="font-size: 12px; color: rgba(255, 255, 255, 0.6); font-weight: 100; margin-bottom: 8px;"> {{
                  item.created_at }}</div>
              <div class="li_right_name"
                style="font-size: 12px; color: rgba(255, 255, 255, 0.6); font-weight: 100; margin-bottom: 8px;">{{
                  $t('lang.s213') }} : {{ formatDate(item.end_date) }}</div>

              <div>
                <div
                  style="font-size: 12px; margin-bottom: 5px;display: flex; align-items: center; justify-content: space-between;">
                  <span>{{ $t('lang.s214') }}</span> <span style="color: rgba(179, 136, 255, 1);">{{
                    productionCom(item.effective_day, item.released_day) }}%</span>
                </div>
                <van-progress :percentage="productionCom(item.effective_day, item.released_day)" stroke-width="8"
                  color="rgba(178, 87, 247, 1)" track-color="none" style="border: 1px solid rgba(178, 87, 247, 1)"
                  :show-pivot="false" />

              </div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <div class="nftlist" v-show="regionIndex == 1">
      <van-list v-model="holdlistIng.loading" :finished="holdlistIng.finished" :finished-text="`${$t('lang.d145')}`"
        @load="onLoadholdIng">
        <div class="nftlist_li" v-for="(item, index) in holdlistIng.list" :key="index" style="padding-top: 11px">
          <div class="nftlist_li_cont">
            <img src="@/assets/imgsnowball/mining_icon.png" alt="" class="li_img" />
            <div class="li_right" style="flex: 1">
              <div class="li_right_name">{{ $t('lang.s111') }}{{ item.cp }} T</div>
              <div class="li_right_name"
                style="font-size: 12px; color: rgba(255, 255, 255, 0.6); font-weight: 100; margin-bottom: 8px;"> {{
                  item.created_at }}</div>
              <div class="li_right_name"
                style="font-size: 12px; color: rgba(255, 255, 255, 0.6); font-weight: 100; margin-bottom: 8px;">{{
                  $t('lang.s213') }} : {{ formatDate(item.end_date) }}</div>

              <div>
                <div
                  style="font-size: 12px; margin-bottom: 5px;display: flex; align-items: center; justify-content: space-between;">
                  <span>{{ $t('lang.s214') }}</span> <span style="color: rgba(179, 136, 255, 1);">{{
                    productionCom(item.effective_day, item.released_day) }}%</span>
                </div>
                <van-progress :percentage="productionCom(item.effective_day, item.released_day)" stroke-width="8"
                  color="rgba(178, 87, 247, 1)" track-color="none" style="border: 1px solid rgba(178, 87, 247, 1)"
                  :show-pivot="false" />

              </div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <div class="nftlist" v-show="regionIndex == 2">
      <van-list v-model="holdlistEnd.loading" :finished="holdlistEnd.finished" :finished-text="`${$t('lang.d145')}`"
        @load="onLoadholdEnd">
        <div class="nftlist_li" v-for="(item, index) in holdlistEnd.list" :key="index" style="padding-top: 11px">
          <div class="nftlist_li_cont">
            <img src="@/assets/imgsnowball/mining_icon.png" alt="" class="li_img" />
            <div class="li_right" style="flex: 1">
              <div class="li_right_name">{{ $t('lang.s111') }}{{ item.cp }} T</div>
              <div class="li_right_name"
                style="font-size: 12px; color: rgba(255, 255, 255, 0.6); font-weight: 100; margin-bottom: 8px;"> {{
                  item.created_at }}</div>
              <div class="li_right_name"
                style="font-size: 12px; color: rgba(255, 255, 255, 0.6); font-weight: 100; margin-bottom: 8px;">{{
                  $t('lang.s213') }} : {{ formatDate(item.end_date) }}</div>

              <div>
                <div
                  style="font-size: 12px; margin-bottom: 5px;display: flex; align-items: center; justify-content: space-between;">
                  <span>{{ $t('lang.s214') }}</span> <span style="color: rgba(179, 136, 255, 1);">{{
                    productionCom(item.effective_day, item.released_day) }}%</span>
                </div>
                <van-progress :percentage="productionCom(item.effective_day, item.released_day)" stroke-width="8"
                  color="rgba(178, 87, 247, 1)" track-color="none" style="border: 1px solid rgba(178, 87, 247, 1)"
                  :show-pivot="false" />

              </div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <van-popup v-model="payshow" position="bottom" round closeable>
      <div class="paypop">
        <div class="title">
          {{ $t('lang.s117') }}
        </div>
       
        <div class="paypop_cont">
          <div class="paypop_checks">
         
            <div class="paypop_checks_box">
              <span>{{ payNumRatePrice(nftItemdata.payData, nftItemdata.roundsData, 't1') }}</span>
             
            </div>
            <div class="paypop_checks_box" style="color: rgba(178, 87, 247, 1); border: none; justify-content: center; font-size: 34px; padding: 0;">
              <span>+</span>
            
            </div>
            <div class="paypop_checks_box">
              <span>{{ payNumRatePrice(nftItemdata.payData, nftItemdata.roundsData, 't2') }}</span>
            
            </div>
          </div>
         
          <div class="banlace">
            <img src="@/assets/imgsnowball/banlce.png" alt="">
            <div class="banlace_box">
              <div style="font-size: 16px; color: rgba(0, 0, 0, 1); font-weight: 700; margin-bottom: 7px;">{{
                $t('lang.s118') }}</div>
              <div style="display: flex;">
                <div style="width: 80px;">{{nftItemdata.t1banlce.token}}{{ $t('lang.d192') }}:</div> {{nftItemdata.t1banlce.usable}}
              </div>
              <div style="display: flex;">
                <div style="width: 80px;">{{nftItemdata.t2banlce.token}}{{ $t('lang.d192') }}:</div> {{nftItemdata.t2banlce.usable}}
              </div>
            </div>
        
          </div>
          <div class="button" @click="nodecpbuy(nftItemdata.roundsData)">{{ $t('lang.s120') }}</div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="paystatus" position="bottom" round>
      <div class="paystatus">
        <img src="@/assets/imgsnowball/paystatus_success.png" alt="">
        <div class="paystatus_text">{{ $t('lang.s215') }}</div>
        <van-button type="primary" round class="paystatus_button" @click="paystatus = false">

          <span> {{ $t('lang.d197') }}</span>
        </van-button>
       
      </div>
    </van-popup>
  </div>
</template>

<script>
import { nft, user } from '../../api/index';
import { mapState } from 'vuex';
import basicUrl from "@/utils/config.js";
import Web3 from 'web3';
export default {
  data() {
    return {
      paystatus:false,
      payLoading: false,
      customabsBoolean: false,
      contentshow: false,
      selectPayData: {
        id:1
      },
      loading: false,
      functionOption: [{ name: 'lang.s165' }, { name: 'lang.s166' }, { name: 'lang.s167' }],
      regionIndex: '0',
      value: 1,
      slidervalue: 20,
      nftlistdata: [],
      nftItemdata: {
      roundsData:{},
      payData:{},
      t1banlce:{},
      t2banlce:{}
      },
      basicUrlData: '',
      payshow: false,
      checkdata: '0',
      ucinfo: {},
      hold_summary: {},
      ranklist: {
        startlist: [],
        endlist: [],
      },
      summaryinfo: {},
      holdlistall: {
        loading: false,
        finished: false,
        list: [],
        info: {
          page: 1,
          pageSize: 10,
          status: '-1'
        }

      },
      holdlistIng: {
        loading: false,
        finished: false,
        list: [],
        info: {
          page: 1,
          pageSize: 10,
          status: '1'
        }

      },
      holdlistEnd: {
        loading: false,
        finished: false,
        list: [],
        info: {
          page: 1,
          pageSize: 10,
          status: '0'
        }

      },
      cproundsInfo: {
        pay_list: [],
        round_list: [],
        loading: true,
      }



    };
  },
  computed: {
    ...mapState(['account', 'lang']),
  },
  watch: {
    account() {

      this.init()
    },
    lang() {
      this.init()
    }
  },
  components: {},
  created() {
    this.init()
  },
  mounted() { },
  methods: {
    init() {


      if (this.account) {
        this.loading = true
        this.cproundsInfo.loading = true
        this.basicUrlData = basicUrl.http_url
        this.holdlistall = {
          loading: false,
          finished: false,
          list: [],
          info: {
            page: 1,
            pageSize: 10,
            status: '-1'
          }

        }
        this.holdlistIng = {
          loading: false,
          finished: false,
          list: [],
          info: {
            page: 1,
            pageSize: 10,
            status: '1'
          }

        }
        this.holdlistEnd = {
          loading: false,
          finished: false,
          list: [],
          info: {
            page: 1,
            pageSize: 10,
            status: '0'
          }

        }

       
        this.nftholdlistiall()
        this.nftholdlistiIng()
        this.nftholdlistiEnd()

        this.nftsummaryinfo()
        this.nodecprounds()
        this.getucinfo()
        this.$nextTick(() => {

        })
      }

    },
    getucinfo() {
      user.getucinfo(this.account).then(res => {
        if (res.code == 200) {
          this.ucinfo = res.data
        }
      })
    },
    async nodecpbuy(item) {
      this.payshow = false
      if (this.ucinfo.parent_id <= 0) {
        this.$toast(this.$t('lang.swap371'))
        this.$router.push('/Invitation')
        return;
      }

      // if (this.selectPayData == null) return this.$toast(this.$t('lang.s168'));
      let sign = null
      try {
        let web3 = new Web3(window.ethereum);
        sign = await web3.eth.personal.sign(web3.utils.fromUtf8('Node CP Buy'), this.account);
      } catch (error) {
        return;
      }


      let data = {
        round_id: item.id,
        pay_id: this.selectPayData.id,
        amount: parseFloat(item.check_num) - parseFloat(item.hold_num_map),
        signature: sign
      }
      nft.nodecpbuy(data, this.account).then(res => {
        if (res.code == 200) {
          this.init()
          this.paystatus = true
        }else{
          this.$toast(res.msg)
        }
       
      })
    },
    selectPayFun(payitem) {
      if (payitem.status == 0) {
        this.$toast(this.$t('lang.s169'))
        return;
      }
      this.selectPayData = payitem
    },
    payNumRatePrice(payitem, item, type) {

      switch (type) {
        case 't1': {
          return parseFloat((((parseFloat(item.check_num) - parseFloat(item.hold_num_map)) * item.price) * payitem.t1_rate) / payitem.t1_price).toFixed(2) + ' ' + payitem.t1_name
        }
        case 't2': {
          return parseFloat((((parseFloat(item.check_num) - parseFloat(item.hold_num_map)) * item.price) * (1 - payitem.t1_rate)) / payitem.t2_price).toFixed(2) + ' ' + payitem.t2_name
        
        }
        default: {
          return 'xx'
        }
          break;
      }

    },
    formatDate(date) {
      const d = new Date(date);
      const year = d.getFullYear();
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const day = String(d.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    swipechange() {

    },
    InputValueFun() {
      this.customabsBoolean = true
    },
    changeFun(item) {
      if (item.check_num <= item.hold_num_map) {
        if (item.hold_num_map > 10) {
          item.check_num = 10
        } else {
          item.check_num = item.hold_num_map
        }

      }


    },
    changeValueFun() {

      this.customabsBoolean = false
    },
    nodecprounds() {
      nft.nodecprounds(this.account).then(res => {
        if (res.code == 200) {
          this.cproundsInfo.pay_list = res.data.pay_list
          this.selectPayData = this.cproundsInfo.pay_list[0]
          let round_list = []
          round_list = res.data.round_list
          round_list.forEach(item => {

            
            if (item.hold_num > 10) {
              item.check_num = 10
              item.hold_num_map = 10
            } else {
              item.check_num = item.hold_num
              item.hold_num_map = item.hold_num
            }

            if (item.max_buy < 10) {
              item.max_buy = 10
            }

          })
          this.cproundsInfo.round_list = round_list
          this.cproundsInfo.loading = false
        }

      })
    },
    nftsummaryinfo() {
      nft.nftsummaryinfo(this.account).then(res => {

        this.hold_summary = res.data.summary
        this.loading = false
      })
      nft.nodeuserteaminfo(this.account).then(res => {
        if (res.code == 200) {
          this.summaryinfo = res.data.level_info
        }

      })
    },
    productionCom(effective_day, released_day) {
      return parseFloat(released_day / effective_day).toFixed(2) * 100
    },
    roundToInteger(num) {
      return parseFloat(num).toFixed(3);
    },
    onLoadholdall() {
      this.nftholdlistiall()
    },
    onLoadholdIng() {
      this.nftholdlistiIng()
    },
    onLoadholdEnd() {
      this.nftholdlistiEnd()
    },
    nftholdlistiall() {

      nft.nftholdlist(this.holdlistall.info, this.account).then(res => {
        if (res.data != null || res.data.length > 0) {
          if (this.holdlistall.info.page == 1) {
            this.holdlistall.list = res.data
          } else {
            this.holdlistall.list = this.holdlistall.list.concat(res.data)
          }
          if (res.data.length < this.holdlistall.info.pageSize) {
            this.holdlistall.loading = true
            this.holdlistall.finished = true
          } else {
            this.holdlistall.info.page++
          }

        }

      })
    },

    nftholdlistiIng() {

      nft.nftholdlist(this.holdlistIng.info, this.account).then(res => {
        if (res.data != null || res.data.length > 0) {
          if (this.holdlistIng.info.page == 1) {
            this.holdlistIng.list = res.data
          } else {
            this.holdlistIng.list = this.holdlistIng.list.concat(res.data)
          }
          if (res.data.length < this.holdlistIng.info.pageSize) {
            this.holdlistIng.loading = true
            this.holdlistIng.finished = true
          } else {
            this.holdlistIng.info.page++
          }

        }

      })
    },
    nftholdlistiEnd() {

      nft.nftholdlist(this.holdlistEnd.info, this.account).then(res => {
        if (res.data != null || res.data.length > 0) {
          if (this.holdlistEnd.info.page == 1) {
            this.holdlistEnd.list = res.data
          } else {
            this.holdlistEnd.list = this.holdlistEnd.list.concat(res.data)
          }
          if (res.data.length < this.holdlistEnd.info.pageSize) {
            this.holdlistEnd.loading = true
            this.holdlistEnd.finished = true
          } else {
            this.holdlistEnd.info.page++
          }

        }

      })
    },
    async getbanlace(token_key) {
     let res = await nft.walletinfobykey({ token_key: token_key }, this.account)
        if (res.code == 200) {
           return res.data
          }else{
            return null
          }
    },
    checkdataFun(index) {
      if (index == '0') {
        this.checkdata = index
      }

    },
    async paypopFun(payitem,item) {
      this.payLoading = true
      this.nftItemdata.payData = payitem
      this.nftItemdata.roundsData = item
      this.nftItemdata.t1banlce = await this.getbanlace(this.nftItemdata.payData.t1_name)
      this.nftItemdata.t2banlce = await this.getbanlace(this.nftItemdata.payData.t2_name)
      this.payLoading = false
      this.payshow = true
    },


    toprouter() {
      this.$router.go(-1);
    },

    switchovers(index) {
      this.regionIndex = index;

      switch (this.regionIndex) {
        default:
          break;
      }

    },
    gopath(router, i) {
      switch (router) {
        case 'nftbill':
          this.$router.push('/nftbill')
          break;
        case 'engbill':

          this.$router.push({ path: '/engbill', query: { regionIndex: i } })
          break;
        case 'user':
          {
            this.regionIndex = 1;
            var element = document.getElementById('myElementId');
            element.scrollIntoView({ behavior: "smooth" });
          }
          break;
        default:
          break;
      }
    }
  },
};
</script>
<style scoped lang='less'>
.paystatus {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 347px;
  padding: 51px 20px;

  img {
    width: 60px;
    height: 60px;
  }

  .paystatus_button {
    border: none;
    width: 100%;
    border-radius: 110px;
    background: rgba(178, 87, 247, 1);
    font-size: 16px;
    font-weight: 700;
    color: #000 !important;
  }

  .paystatus_text {

    margin-top: 18px;
    margin-bottom: 75px;
    font-size: 18px;
    font-weight: 700;
    color: rgba(34, 34, 34, 1);
  }
}

.recruit_title {
  color: var(rgba(255, 255, 255, 1));
  padding: 20px 0px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contentshow {
  width: 335px;

  .contentshow_title {
    padding: 20px;
    background: #fff;
    padding-bottom: 17px;
    border-bottom: 1px solid rgba(242, 242, 242, 1);
    font-size: 16px;
    color: rgba(34, 34, 34, 1);
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 21px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .contentshow_box {
    background: #fff;
    padding: 20px;
    padding-bottom: 40px;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0px;
    max-height: 330px;
    overflow-y: scroll;
    color: rgba(34, 34, 34, 1) !important;
  }
}

.custom-button {
  border-radius: 50%;
  width: 21px;
  height: 21px;
  opacity: 1;
  background: rgba(254, 219, 147, 1);
  box-shadow: 0px 0px 12px rgba(254, 219, 147, 0.43);
  position: relative;

  .custom_abs {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -15px;
    color: rgba(254, 219, 147, 1);
    font-size: 12px;
    font-weight: 700;
    white-space: nowrap;
  }
}

.changeMingbox {

  margin: 0 auto;
  margin-top: 26px;
  width: 335px;
  min-height: 359px;
  opacity: 1;
  border-radius: 16px;
  padding: 16px;
  background: rgba(27, 31, 46, 1);

  border: 1px solid rgba(0, 0, 0, 1);

  .changeMingbox_line {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
  }

  .button {
    margin-top: 23px;
    height: 48px;
    opacity: 1;
    border-radius: 10px;
    background: rgba(178, 87, 247, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 700;
    color: #000;
  }

  .paypop_checks_box {
    padding: 14px 19px;
    font-size: 16px;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;

    border-radius: 4px;
    background: rgba(44, 49, 66, 1);
    margin-bottom: 14px;

    .checkbox {
      width: 14px;
      height: 14px;

      img {
        width: 100%;
      }
    }
  }
}

.minboxs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .minbox {
    margin-top: 14px;
    border-radius: 4px;
    background: rgba(178, 87, 247, 0.1);

    border: 1px solid rgba(178, 87, 247, 1);
    width: 305px;
    height: 46px;
    padding: 11px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);

    img {
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }
  }
}

.paypop {
  width: 100%;

  .title {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    padding: 19px 20px;
    color: rgba(34, 34, 34, 1);
    border-bottom: 1px solid rgba(242, 242, 242, 1);
  }

  .paypop_cont {
    padding: 40px 21px;

    .button {
      margin-top: 23px;
      height: 48px;
      opacity: 1;
      border-radius: 110px;
      background: rgba(178, 87, 247, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 700;
      color: #000;
    }

    .banlace {
      padding-bottom: 7px;
      border-bottom: 1px solid rgba(242, 242, 242, 1);
      display: flex;

      img {
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }

      .banlace_box {
        color: rgba(153, 153, 153, 1);
        font-size: 12px;
      }
    }

    .paypop_checks {
      width: 100%;

      .paypop_checks_box {
        padding: 14px 19px;
        font-size: 16px;
        font-weight: 700;
        color: rgba(0, 0, 0, 1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 8px;

        border: 1px solid rgba(230, 230, 230, 1);
        margin-bottom: 14px;

        .checkbox {
          width: 14px;
          height: 14px;

          img {
            width: 100%;
          }
        }
      }
    }
  }
}

.nftcenter {

  padding-bottom: 44px;
}

.recruit_title {
  color: var(rgba(255, 255, 255, 1));
  padding: 20px 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nftlist {
  width: 100%;
  padding: 0 20px;

  .nftlist_li {
    padding: 25px 16px 20px 16px;
    width: 100%;
    opacity: 1;
    border-radius: 10px;
    background: rgba(27, 31, 46, 1);
    margin-bottom: 20px;
    border: 1px solid rgba(52, 247, 244, 0.49);

    .button_active {
      border-radius: 53px !important;
      background: rgba(152, 232, 110, 1) !important;
      color: #000 !important;
    }

    .nftlist_li_button {
      margin-top: 30px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 700;
      color: #fff;
      height: 44px;
      opacity: 1;
      border-radius: 10px;
      background: rgba(0, 0, 0, 1);
    }

    .nftlist_li_cont {
      display: flex;
      align-items: center;

      .li_right {
        .poptuct {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: 700;
        }

        .li_right_name {
          font-size: 14px;
          font-weight: 700;
          color: #fff;
          margin-bottom: 3px;
        }

        .li_right_pop {
          font-size: 12px;
          font-weight: 500;
          color: #fff;
          margin-top: 6px;
        }
      }

      .li_img {
        margin-right: 18px;
        width: 106px;
        height: 106px;
      }
    }
  }
}

.functional_region {
  margin-top: 36px;
  padding: 10px 20px 0;
  padding-top: 0;

  .region {
    display: flex;
    width: 70%;
    line-height: 22px;
    font-size: 16px;
    border-radius: 30px;
    padding: 3px;

    font-family: PingFangSC-Semibold, PingFang SC;
    color: rgba(158, 156, 163, 1);
    font-weight: 600;
    // background:  rgba(69, 131, 255, 1);
    border-radius: 34px;
    // margin: 20px 0;
    margin-bottom: 20px;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      text-align: center;
      // width: 192rpx;
      padding: 10px 0;
    }

    .special {
      // width: 50%;
      position: relative;
      transform: scale(1.1);
      color: #fff;

      &::after {
        content: '';
        position: absolute;
        bottom: 4px;
        left: 50%;
        transform: translateX(-50%);
        width: 15px;
        height: 2px;
        opacity: 1;
        border-radius: 29px;
        background: rgba(178, 87, 247, 1);
      }
    }
  }
}

.dotdlist {
  border-radius: 31px;
  padding: 0 20px;
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
  font-size: 12px;
  color: rgba(255, 255, 255, 1);

  .dotdlist_li {
    margin-bottom: 12px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .dotdlist_li_index {
      font-size: 14px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      margin-right: 18px;
    }

    .dotdlist_li_box {
      border-radius: 31px;
      padding: 13px 19px;
      width: 274px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: rgba(27, 31, 46, 1);
    }
  }
}

.dotdbox {
  margin-top: 34px;
  margin-bottom: 14px;
  width: 100%;
  background: url('~@/assets/imgsnowball/dotd_bg.png') no-repeat;
  background-size: cover;

  .dotdbox_title {
    margin-bottom: 14px;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dotdbox_cont {
    border-radius: 4px;
    margin: 0 auto;
    width: 339px;
    height: 199px;
    opacity: 1;
    border-radius: 4px;
    background: rgba(27, 31, 46, 0.9);

    border: 1px solid rgba(0, 0, 0, 1);
    display: flex;
    align-items: center;
    justify-content: space-around;

    .dotdbox_cont_level {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .level_user {
        margin-top: 6px;
        color: rgba(255, 255, 255, 1);
        font-size: 12px;
      }

      .level_index {
        font-size: 20px;
        font-weight: 400;
        color: #fff;
      }

      .level_logo {
        margin-top: 6px;
        margin-bottom: 5px;
        width: 32px;
        height: 36px;
      }

      .level_pop {
        color: rgba(0, 0, 0, 1);
        border-radius: 26px;
        background: rgba(102, 206, 253, 1);
        text-align: center;
        padding: 2px 8px;
        font-size: 12px;
      }
    }
  }
}

.lookboxs {
  display: flex;
  width: 335px;
  margin: 0 auto;
  margin-top: 24px;
  justify-content: space-between;
  align-items: center;

  .lookbox {
    position: relative;
    background-clip: padding-box;
    width: 103.77px;
    padding-bottom: 6px;
    border: 1px solid rgba(181, 88, 246, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

    .abs_look {
      position: absolute;
      right: 6px;
      top: 6px;
      padding: 3px;
      border-radius: 4px;
      background: rgba(52, 247, 244, 1);
      color: rgba(0, 0, 0, 1);
      font-size: 12px;
    }

    .box {
      width: 88.09px;
      padding: 16px 0 9px 0;
      display: flex;
      border-radius: 8px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      background: linear-gradient(0deg, rgba(181, 88, 246, 0.44) 0%, rgba(13, 8, 26, 0) 100%);
    }

    .box_num {
      font-size: 18px;
      color: rgba(255, 255, 255, 1);
    }

    .box_title {
      font-size: 12px;
      color: rgba(255, 255, 255, 1);
    }

    img {
      margin-top: 10px;
      margin-bottom: 14px;
      width: 26px;
      height: 26px;
    }
  }
}

.nftcenter_sum {
  padding: 13px 17px;
  width: 335px;

  margin: 0 auto;
  border-radius: 8px;
  background: linear-gradient(90deg, rgba(180, 87, 247, 1) 0%, rgba(255, 222, 145, 1) 100%);

  .button {
    width: 53px;
    height: 30px;
    opacity: 1;
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: rgba(102, 61, 184, 1);
  }

  .sumbox_img {
    width: 62px;
    height: 93px;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sumbox {
    font-size: 12px;

    .sumbox_title {
      color: rgba(255, 255, 255, 0.6);
      margin-bottom: 4px;
    }

    .sumbox_num {
      font-size: 20px;
      color: #fff;
    }
  }
}
</style>