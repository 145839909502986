export const lang = {
  swap1: 'hoán đổi flash',
  swap2: 'Bắt ​​đầu swapi flash ngay lập tức',
  swapi3: 'từ',
  swap4: 'số dư: ',
  swap5: 'Chọn loại tiền tệ',
  swap6: 'tối đa',
  swap7: 'giá',
  swap8: 'Khả năng chịu trượt',
  swap9: 'Kết nối ví',
  swap10: 'Số lượng đầu vào',
  swap11: 'Số dư không đủ',
  swap12: 'Không đủ thanh khoản cho giao dịch này',
  swap13: 'Chọn mã thông báo',
  swap14: 'Ủy quyền',
  swap15: 'Đóng gói',
  swap16: 'giải nén',
  swap17: 'hoán đổi flash',
  swap18: 'Biên lai giao dịch',
  swap19: 'Đang chờ xác nhận',
  swap20: 'đang swapi',
  swap21: 'Đổi lấy',
  swap22: 'Xác nhận giao dịch này trong ví của bạn',
  swap23: 'Giao dịch đã được gửi',
  swap24: 'Xem trên BSC',
  swap25: 'Đóng',
  swap26: 'Giao dịch bị từ chối',
  swap27: 'Xác nhận swapi',
  swap28: 'Đầu ra là một giá trị định giá, bạn sẽ nhận được ít nhất {inputOutMin} {symbol}, nếu không giao dịch sẽ bị rút',
  swap29: 'Đầu vào là định giá, bạn sẽ bán tối đa {inputInMax} {symbol}, nếu không giao dịch sẽ bị rút',
  swap30: 'Số tiền tối thiểu thu được',
  swap31: 'Số tiền bán tối đa',
  swap32: 'đến',
  swap33: 'Mã thông báo quản lý',
  swap34: 'Nhập mã thông báo',
  swap35: 'nhập',
  swap36: 'Quản lý mã thông báo',
  swap37: 'Mã thông báo tùy chỉnh',
  swap38: 'Xóa tất cả',
  swap39: 'Tìm kiếm tên hoặc dán địa chỉ',
  swap40: 'cài đặt',
  swap41: 'Tốc độ giao dịch mặc định (GWEI)',
  swap42: 'tiêu chuẩn',
  swap43: 'nhanh',
  swap44: 'tức thì',
  swap46: 'Nhập phần trăm trượt giá hợp lệ',
  swap47: 'Giao dịch của bạn có thể thất bại',
  swap48:'Giao dịch của bạn có thể được nâng cao',
  swap49: 'Thời hạn giao dịch (phút)',
  swap50: 'ước tính',
  swap51: 'Vô hiệu hóa multi-hop',
    swap52: 'Giao dịch mới nhất',
    swap53: 'Không có giao dịch gần đây',
    swap54: 'Xóa tất cả',
    swap55: 'Liên kết ví',
    swap56: 'Tham gia hội đồng quản trị',
    swap57: 'Quyên góp',
    swap58: 'Quà tặng vốn sở hữu',
    swap59: 'Danh sách thành viên hội đồng quản trị',
    swap60: 'mời',
    swap61: 'Sao chép',
    swap62: 'Người mời DAPP của tôi:',
    swap63: 'bản ghi lời mời',
    swap64: 'Đã xảy ra lỗi, vui lòng thử lại sau',
    swap65: 'Chức năng này chưa khả dụng',
    swap66: 'Nhận',
    swap67: 'Xóa thành công',
    swap68: 'Đang quyên góp, chờ xác nhận',
    swap69: 'Đã quyên góp',
    swap70: 'Người dùng không thuộc danh sách trắng',
    swap71: 'Đóng góp không hợp lệ',
    swap72: 'Số dư hiện tại không đủ',
    swap73: 'Đang cấp phép, vui lòng đợi',
    swap74: 'Ủy quyền không thành công',
    swap75: 'Quyên góp, vui lòng đợi',
    swap76: 'Quyên góp không thành công',
    swap77: 'Trao đổi',
    swap78: 'Thanh khoản',
    swap79: 'Thành viên',
    swap80: 'mời',
    swap81: 'Stake Pool',
    swap82: 'Cầu xuyên chuỗi',
    swap83: 'Hồ bơi',
    swap84: 'Trang trại',
    swap85: 'trang web chính thức',
    swap86: 'Chợ',
    swap87: 'Trao đổi',
    swap88: 'Giao dịch',
    swap89: 'Kiếm tiền',
    swap90: 'xác nhận',
    swap91: 'tác động đến giá',
    swap92: 'Số tiền tối thiểu thu được',
    swap93: 'Phí nhà cung cấp thanh khoản',
    swap94: 'Tăng tính thanh khoản',
    swap95: 'Tăng tính thanh khoản để nhận token LP',
    swap96: 'Chia sẻ trong nhóm thanh khoản',
    swap97: 'Tỷ giá hối đoái và chia sẻ nhóm thanh khoản',
    swap98: 'Thanh khoản của bạn',
    swap99: 'Xóa thanh khoản để lấy lại token',
    swap100: 'Không tìm thấy thanh khoản',
    swap101: 'Bạn không thấy nhóm thanh khoản mà bạn đã tham gia? ',
    swap102: 'Xem các token LP khác',
    swap103: 'Đã vào nhóm',
    swap104: 'Xóa',
    swap105: 'cung cấp',
    swap106: 'Nhập nhóm',
    swap107: 'Nhập nhóm thanh khoản hiện có',
    swap108: 'Chọn token để tìm tính thanh khoản của bạn. ',
    swap109: 'Bạn chưa cung cấp thanh khoản trong nhóm thanh khoản này. ',
    swap110: 'Mã thông báo LP trong ví của bạn',
    swap111: 'Nhận',
    swap112: 'và',
    swap113: 'Số tiền',
    swap114: 'Bạn sẽ nhận được',
    swap115: 'Kết quả đầu ra là ước tính. Nếu giá thay đổi hơn {slippage}%, giao dịch của bạn sẽ bị đảo ngược',
    swap116: 'Cháy',
    swap117: 'Đã gửi tiền',
    swap118: 'Xác nhận nguồn cung',
    swap119: 'Tỷ giá hối đoái',
    swap120: 'Bạn là nhà cung cấp thanh khoản đầu tiên. ',
    swap121: 'Tỷ lệ mã thông báo bạn thêm sẽ xác định giá ban đầu của nhóm thanh khoản. ',
    swap122: 'Nếu bạn hài lòng với tỷ giá hối đoái, hãy nhấp vào Cung cấp để kiểm tra. ',
    swap123: 'Xóa',
    swap124: 'Cung cấp',
    swap125: 'Xác nhận nguồn cung',
    swap126: 'Sách trắng',
    swap127: 'Kiểm toán',
    swap128: 'Thông báo',
    swap129: 'nhắc',
    swap130: 'Trao đổi PHO 1:1',
    swap131: 'thêm',
    swap132: 'Đang tải',
    swap133: 'thông tin',
    swap134: 'Hạn mức quyên góp đã đầy',
    swap135: 'PHO của tôi',
    swap136: 'PHO trong ví',
    swap137: 'PHO có thể thu hoạch được',
    swap138: 'giá PHO',
    swap139: 'Tổng lượng PHO',
    swap140: 'Giá trị thị trường của PHO',
    swap141: 'Thông tin PHO',
    swap142: 'Kiếm được',
    swap143: 'cam kết',
    swap144: 'TVL cá nhân',
    swap145: 'TVL cộng đồng',
    swap146: 'thu hoạch',
    swap147: 'Ưu đãi tích lũy',
    swap148: 'Khuyến khích hôm nay',
    swap149: 'Số lượng địa chỉ người bán tham gia',
    swap150: 'Liên kết lời mời của tôi',
    swap151: 'Diện tích sức mạnh tính toán tối đa',
    swap152: 'Các lĩnh vực sức mạnh tính toán khác',
    swap153: 'Lời mời kết bạn của tôi',
    swap154: 'Hủy',
    swap155: 'Sửa đổi người mời',
    swap156: 'Người mời không được để trống',
    swap157: 'Sửa đổi thành công',
    swap158: 'Mã thông báo LP cam kết',
    swap159: 'Đổi mã thông báo LP',
    swap160: 'Quyền chuộc',
    swap161: 'Nhận',
    swap162: 'Hiện tại không có thu nhập',
    swap163: 'định tuyến',
    swap164: 'Cơ quan kiểm toán',
    swap165: 'Đối tác',
    swap166: 'giải phóng',
    swap167: 'Hoạt động',
    swap168: 'Nhóm tiền xu đơn lẻ',
    swap169: 'nhóm LP',
    swap170: 'Quốc hội',
    swap171: 'Thẻ',
    swap172: 'hộp mù',
    swap173: 'trò chơi',
    swap174: 'Tổng số tiền được phân bổ cho vốn cổ phần',
    swap175: 'Số tiền nhận được',
    swap176: 'Tôi đã nhận được số tiền',
    swap177: 'xóa',
    swap178: 'Siêu nút',
    swap179: 'Thẻ vốn chủ sở hữu đã nhận được cổ phần',
    swap180: 'Kho bạc',
    swap181: 'Số tiền tích lũy',
    swap182: 'Số tiền được kho bạc phân bổ',
    swap183: 'Số tiền được kho bạc mua lại',
    swap184: 'Chế độ chuyên gia',
    swap185: 'D6 Swap là nền tảng Defi hệ thống thuế vốn cổ phần đầu tiên trong toàn bộ mạng được phát triển dựa trên OKExChain và đã dần dần ra mắt Swap, NFT, Gamefi, Lend, nhóm súng máy và các lĩnh vực khác. Kể từ khi ra mắt, nó đã cung cấp nhiều giải pháp đánh giá tài sản đa dạng hơn và trải nghiệm chưa từng có thông qua công nghệ hàng đầu cũng như các khái niệm thị trường mở và công bằng, đồng thời được đa số người dùng yêu thích sâu sắc. ',
    swap186: 'Xóa bằng một cú nhấp chuột',
    swap187: 'Tác động lên giá quá cao',
    swap188: 'Câu hỏi thường gặp',
    swap189: 'NFT',
    swap190: 'Kiếm xu',
    swap191: 'đang tiến hành',
    swap192: 'Đã kết thúc',
  
    swap193: 'Mô tả',
    swap194: 'Thẻ sinh thái vốn chủ sở hữu và vốn chủ sở hữu, giới hạn ở 1680 miếng trên toàn thế giới',
    swap195: 'Các yêu cầu và lợi ích của việc kích hoạt thẻ sinh thái vốn chủ sở hữu như sau',
    swap196: 'Thẻ một sao: Địa chỉ cá nhân có thanh khoản trị giá 1000 USDT, nhóm có thanh khoản trị giá 1000 USDT và thu nhập được chia thành tổng thuế giao dịch là 0,1%',
    swap197: 'Thẻ hai sao: Một cá nhân có thanh khoản trị giá 2.000 USDT, một nhóm có thanh khoản trị giá 10.000 USDT và thu nhập được chia thành tổng thuế giao dịch là 0,1%',
    swap198: 'Thẻ Samsung: thanh khoản 3.000 USDT cho một cá nhân, thanh khoản 100.000 USDT cho một nhóm, thành lập studio và tổng thuế giao dịch là 0,1% trên lợi nhuận',
    swap199: 'Thẻ bốn sao: Cá nhân có thanh khoản trị giá 4.000 USDT, nhóm có thanh khoản 1 triệu USDT, thành lập studio và thu nhập được chia thành tổng thuế giao dịch là 0,1%',
    swap200: 'Thẻ năm sao: thanh khoản 5.000 USDT cho cá nhân, thanh khoản 10 triệu USDT cho các nhóm, thành lập studio và lợi nhuận sẽ được chia thành 0,1% thuế giao dịch',
    swap201: 'Lưu ý: Tất cả thu nhập từ thẻ sinh thái vốn được tự động phân phối trên chuỗi. Thẻ vốn sở hữu được hưởng tất cả cổ tức sinh thái của sàn giao dịch. Chúng sẽ được kế thừa, giao dịch và chuyển nhượng tự động nếu đáp ứng các yêu cầu. tự động hạ cấp và thoát nếu không đáp ứng yêu cầu. ',
    swap202: 'Thẻ vốn chủ sở hữu',
    swap203: 'Lộ trình phát triển:',
    swap204: 'Phiên bản 2.0 sẽ trực tuyến vào tháng 7 năm 2022',
    swap205: 'Vào tháng 9 năm 2022, hãy giữ tiền và khai thác nhiều hơn',
    swap206: 'Nền tảng giao dịch NFT sẽ được ra mắt vào tháng 12 năm 2022',
    swap207: 'Trò chơi Goodbaby sẽ ra mắt vào tháng 6 năm 2023',
    swap208: 'Goodlend sẽ trực tuyến vào tháng 9 năm 2023',
    swap209: 'D6 Giới thiệu về hoán đổi:',
    swap210: 'Trượt giá:',
    swap211: 'Phân bổ:',
    swap212: 'Mua',
    swap213: 'Bán',
    swap214: 'cổ tức LP 0,5%',
    swap215: 'cổ tức thẻ vốn cổ phần sinh thái 0,5%',
    swap216: '0,25% quay trở lại hũ để hủy LP',
    swap217: '0,25% xây dựng sinh thái',
    swap218: 'Chưa có phần thưởng',
    swap219: 'TVL cá nhân',
    swap220: 'Đội TVL',
    swap221: 'số lượng LP',
    swap222: 'phở trong ví',
    swap223: 'GDT',
    swap224: 'Phần thưởng tích lũy airdrop GDT',
    swap225: 'Phần thưởng tích lũy của người dùng sao',
    swap226: 'Đếm ngược miễn phí',
    swap227: 'phần thưởng có thể được rút',
    swap228: 'Phần thưởng khai thác LP',
    swap229: 'Phở cam kết',
    swap230: 'Đổi phở',
    swap231: 'Đồng thuận mục tiêu',
    swap232: 'Đạt được sự đồng thuận',
    swap233: 'Đồng thuận',
    swap234: 'Đồng thuận',
    swap235: 'Đã mở khóa',
    swap236: 'Có sẵn',
    swap237: 'Quản trị cộng đồng',
    swap238: 'Đồng thuận liên minh',
    swap239: 'Liên minh cộng đồng',
    swap240: 'Mã thông báo cam kết',
    swap241: 'Stake, vui lòng đợi',
    swap242: 'Khai thác thương mại',
    swap243: 'Mới được thêm ngày hôm qua',
    swap244: 'Phần thưởng tích lũy danh sách trắng cộng đồng',
    swap245: 'Phần thưởng tích lũy Thẻ Ngôi sao Vốn chủ sở hữu',
    swap246: 'Phần thưởng tích lũy khai thác LP',
    swap247: 'Hiện có sẵn để sưu tập',
    swap248: 'Số tiền hiện có sẵn',
    swap249: 'Khai thác thương mại',
    swap250: 'Tích lũy phần thưởng chia sẻ của tôi',
    swap251: 'Phần thưởng giao dịch của tôi đã tích lũy',
    swap252: 'Vui lòng nhập hàm băm giao dịch của bạn',
    swap253: 'xác minh',
    swap254: 'Vui lòng nhập hàm băm của bạn',
    swap255: 'Địa chỉ giao dịch của bạn',
    swap256: 'Bạn sẽ nhận được',
    swap257: 'Chỉ những giao dịch mua GDT và tạo thanh khoản mới nhận được phần thưởng',
    swap258: 'Cầm cố token GDT-PHO',
    swap259: 'Đổi mã thông báo GDT-PHO',
    swap260: 'Tích lũy phí xử lý trên toàn bộ mạng',
    swap261: 'Để nhận cổ tức',
    swap262: 'Cổ đông chung GDT',
    swap263: 'xã hội',
    swap264: 'trung tâm mua sắm',
    swap265: 'Hàng được chọn',
    swap266: 'Cửa hàng ưa thích',
    swap267: 'Sắp ra mắt',
    swap268: 'Cổ đông VGT Lianchuang',
    swap269: 'Cầm cố token VGT-PHO',
    swap270: 'Đổi mã thông báo VGT-PHO',
    swap271: 'Liên kết được đề xuất',
    swap272: 'Hãy trói thủ lĩnh',
  
    swap273: 'Chi tiết',
    swap274: 'Metaverse',
    swap275: 'toàn cầu',
    swap276: 'Hoán đổi và thanh khoản',
    swap277: 'Giao dịch, phát triển, kiếm tiền,',
    swap278: 'Nền tảng sinh thái DEX tổng hợp đa chuỗi',
    swap279: 'Tận hưởng phí giao dịch thấp nhất, các tính năng sinh lời và chương trình giới thiệu đa loại',
    swap280: 'Lộ trình',
    swap281: 'Phiên bản 2.0 sẽ trực tuyến vào tháng 7 năm 2022',
    swap282: 'Vào tháng 9 năm 2022, việc nắm giữ tiền tệ để khai thác nhiều hơn sẽ được triển khai',
    swap283: 'Nền tảng giao dịch NFT sẽ được ra mắt vào tháng 12 năm 2022',
    swap284: 'Trò chơi Goodbaby sẽ ra mắt vào tháng 6 năm 2023',
    swap285: 'Goodlend sẽ ra mắt vào tháng 9 năm 2023',
    swap286: 'Tìm hiểu thêm',
    swap287: 'Bạn đã giữ rồi',
    swap288: 'trong',
    swap289: 'Giao dịch tiền điện tử',
    swap290: 'Stake thanh khoản và token',
    swap291: 'Kiếm phần thưởng cổ tức',
    swap292: 'bệ phóng',
    swap293: 'xác thực',
    swap294: 'Tài sản kỹ thuật số được mã hóa chất lượng cao',
    swap295: 'hiểu',
    swap296: 'tác phẩm nghệ thuật',
    swap297: 'Thị trường giao dịch',
    swap298: 'Duyệt',
    swap299: 'Trao đổi token tức thì',
    swap300: 'Sao chép thành công',
    swap301: 'Không hỗ trợ sao chép, trình duyệt này không hỗ trợ sao chép tự động',
    swap302: 'Phần thưởng tích lũy Lianchuang',
    swap303: 'Phần thưởng chia sẻ tích lũy',
    swap304: 'Phần thưởng giao dịch tích lũy',
    swap305: 'Gói tài sản',
    swap306: 'Giao dịch để kiếm xu',
    swap307: 'Số dư có thể rút được',
    swap308: 'Vui lòng nhập hàm băm giao dịch',
    swap309: 'Được',
    swap310:'Vui lòng nhập giá trị băm',
    swap311:'Gửi tiền thành công',
  
    swap312:'Binance',
    swap313:'nền tảng',
    swap314:'khu vực PHO',
    swap315:'Khu GDT',
    swap316:'Vùng VGT',
  swap317:'Thẻ sao PHO',
  swap318:'cá nhân',
  swap319:'Đội',
  swap320:'trong ví',
  swap321:'Chọn gói tài sản',
  swap322:'Hãy chọn',
  swap323:'Tỷ lệ lợi nhuận hàng ngày',
  swap324:'Tỷ lệ thu nhập',
  swap325:'Tổng số lần hết hạn',
  swap326:'Mua',
  swap327:'Số dư ví của bạn',
  swap328:'Gói tài sản của tôi',
  swap329:'tổng thu nhập ngày hôm nay',
  swap330:'Thu nhập tĩnh của ngày hôm nay',
  swap331:'Thu nhập động của ngày hôm nay',
  swap332:'Thu nhập tích lũy',
  swap333:'Số lượng hết hạn',
  swap334:'chọn',
  swap335:'Vùng tiền điện tử',
  swap336:'Mua hàng thành công',
  swap337:'Nhập DApp',
  swap338:'vòng tròn',
  swap339: 'Trò chuyện với bạn bè tiền điện tử của bạn',
  swap340:'hộp kẹo',
  swap341:'Airdrop nhiều tài sản chất lượng cao',
  swap342:'Hãy đến và nhận nó miễn phí',
  swap343:'Quỹ đổi mới công',
  swap344:'Trung tâm tiền xu',
  swap345:'vòng cộng đồng',
  swap346:'Hộp kẹo',
  swap347:'Kiếm xu',
  swap348:'thả airdrop',
  swap349:'Trung tâm chia sẻ',
  swap350:'Chương trình liên kết',
  swap351:"xã hội",
  swap352:"cầu",
  swap353:"ánh xạ",
  swap354:"Hồ khai thác nước",
  swap355: "Tham gia xây dựng nhóm khai thác và nhận ưu đãi cao một cách miễn phí",
  swap356:"Nhóm thanh khoản",
  swap357: "Nhóm cam kết bằng một xu",
  swap358:"thời gian thực",
  swap359:"Đã hoàn thành",
  swap360:"Chỉ đặt cược",
  swap361:"Sắp xếp theo",
  swap362:"tìm kiếm",
  swap363:"phổ biến",
  swap364:"Tìm kiếm nhóm khai thác",
  swap365:"Không có gì ở đây cả!",
  swap366: "Mẫu khởi động Water Swap đầu tiên",
  swap367: "Tìm đường dẫn phù hợp nhất cho mọi nhà đầu tư và tài sản tiền điện tử chất lượng",
  swap368:"Tạo ISO",
  swap369: "Trở thành ngôi sao tiền điện tử trong tương lai",
  swap370:"Sắp ra mắt",
  swap371: "Trước tiên hãy ràng buộc người giới thiệu",
  swap372:"người mời của tôi",
  swap373: "Địa chỉ của người mời:",
  swap374:"",
  swap375: "Trong Quý 3, D6 Swap đã được triển khai và ra mắt, đồng thời việc tạo ra tiền cũng được triển khai. Đồng thời, nền tảng xã hội mã hóa CIRCLE được giới thiệu nhằm liên kết sâu sắc sự phát triển sinh thái của cộng đồng.",
  swap376: "Q4 sẽ bơm thanh khoản vào D6, triển khai 'Kế hoạch đồng thuận D6', tạo ra sự đồng thuận lưu thông D6 và kích hoạt kế hoạch khuyến khích thanh khoản. Ra mắt một trung tâm mua sắm xuyên biên giới liên minh trên chuỗi để tạo ra 'giá trị gia tăng tiêu dùng' hệ sinh thái và thúc đẩy sự hòa nhập của cộng đồng và doanh nghiệp New Direction.",
  swap377:"",
  swap378: "Gói hỗ trợ liên minh Q1 có kế hoạch quảng bá D6 dưới dạng hỗ trợ thanh khoản cơ bản, tăng cường nhận diện và chia sẻ trên thị trường, ra mắt D-Pay và thúc đẩy lưu thông giao dịch vật lý của D6 Coin",
  swap379: "D6 Trade chính thức ra mắt vào Quý 2, mở ra kỷ nguyên của nền tảng giao dịch kép CEX và DEX, cải thiện hơn nữa tính thanh khoản giao dịch của thị trường D6 và giới thiệu các đối tác mới.",
  swap380:"",
  swap381:"",
  d1:"Gửi tiền",
  d2:"Rút xu",
  d3:"Chuyển",
  d4:"Hóa đơn",
  d5:"cộng đồng",
  d6:"Trao đổi flash",
  d7:"Phản hồi về sự cố",
  d8:"Đúc",
  d9:"Kiếm xu D6",
  d10:"Lợi nhuận càng cao",
  d11:"nhập",
  d12:"Báo giá nước",
  d13:"Giá hôm nay",
  d14:"Lợi nhuận hôm nay",
  d15:"Xem",
  d16:"Tài sản",
  d17:"Ẩn nội dung bằng 0",
  d18:"tiền tệ",
  d19:"đóng băng",
  d20:"Có sẵn",
  d21: "Chọn thẻ",
  d22: "Số lượng nạp lại",
  d23: "Vui lòng nhập số tiền nạp",
  d24:"Số dư ví:",
  d25:"Địa chỉ gửi tiền",
  d26:"Được",
  d27:"Bản ghi nạp tiền",
  d28:"Trích xuất số lượng",
  d29: "Vui lòng nhập số lượng rút",
  d30:"Số dư tài khoản:",
  d31:"Địa chỉ gửi tiền",
  d32:"Hồ sơ rút tiền",
  d33:"Số lượng chuyển",
  d34: "Vui lòng nhập số tiền chuyển",
  d35:"Nhập địa chỉ của bên kia",
  d36:"Xin vui lòng nhập địa chỉ của bên kia",
  d37:"Bản ghi chuyển giao",
  d38:"Trao đổi tài sản",
  d39:"trả tiền",
  d40:"nhận được",
  d41:"Số lượng",
  d42:"tất cả",
  d43: "Giá trị quy đổi tối đa:",
  d44:"Thuế giao dịch:",
  d45: "Nên ghi có:",
  d46:"Số lượng thanh toán:",
  d47:"Bản ghi đổi thưởng flash",
  d48:"Xác nhận swap",
  d49:"Bản ghi đổi thưởng flash",
  d50:"Kiếm được",
  d51:"Có thể kiếm được",
  d52:"Số lượng đã đốt",
  d53: "Vui lòng nhập số lượng đốt",
  d54:"Đang tiến hành",
  d55:"Đã kết thúc",
  d56: "Tiến trình lưu thông",
  d57: "Chưa có bản ghi nào!",
  d58:"Thu nhập lịch sử",
  d59:"Cấp độ của tôi",
  d60: "Mức độ hiện tại",
  d61: "Bạn có thể trở thành người dùng D0 sau khi đăng ký",
  d62:"Kế hoạch mời bạn bè",
  d63: "Mời bạn bè cùng nhau đúc tiền, bắt đầu cuộc cách mạng đồng thuận Nước và kiếm phần thưởng nhanh hơn!",
  d64:"Sao chép liên kết",
  d65:"Người giới thiệu của tôi",
  d66:"Đề xuất hiệu quả của tôi",
  d67:"địa chỉ",
  d68:"cấp độ",
  d69:"Hiệu suất",
  d70:"Cấp độ tiếp theo:",
  d71:"Hiệu suất thị trường",
  d72:"Chưa hoàn thành",
  d73:"Phòng Tiếp thị",
  d74:"Bạn đã ở cấp độ cao nhất",
  d75:"Thông báo",
  d76:"Xác nhận ràng buộc",
  d77:"Địa chỉ của người lãnh đạo:",
  d78:"Xác nhận ràng buộc",
  d79: "Không thể âm hoặc bằng 0",
  d80: "Số dư không đủ",
  d81: "Gửi tiền thành công",
  d82: "Gửi tiền không thành công",
  d83:"Phí xử lý:",
  d84:"Chuyển",
  d85:"Chuyển ra",
  d86:"Bản ghi hóa đơn",
  d87:"thời gian",
  d88:"loại",
  d89:"Số lượng",
  d90:"tiền tệ",
  d91: "Vui lòng chọn thẻ thanh toán trước",
  d92: "Thẻ này bị cấm swap hai chiều",
  d93: "Không thể chọn cùng một mã thông báo",
  d94: "Trước tiên hãy trói người lãnh đạo",
  d95: "Số tiền có thể đốt còn lại",
  d96: "Trước tiên hãy ràng buộc người giới thiệu",
  d97: "Vui lòng điền mã mời của người giới thiệu",
  d98:"Thông báo mới nhất",
  d99:"Lưu ý:",
  d100: "2: Lệnh rút coin, dự kiến ​​xác nhận vào ngày T+1",
  d101: "1: Một lần rút tiền bắt đầu từ {min_withdraw} {token_name}",
  d102: "3: Thuế 2 USDT sẽ được tính cho mỗi lần rút tiền",
  d103:"Địa chỉ nhóm",
  d104:"Nhóm hiệu quả",
  d105:"Nạp tiền ngay hôm nay",
  d106:"Trích xuất ngay hôm nay",
  d107:"Cá nhân",
  d108:"Đội",
  d109: "Sau khi mở, nếu đáp ứng đủ điều kiện casting thì sẽ tự động tham gia casting",
  d110: "Dự kiến ​​sẽ đến sau 1-2 phút sau khi sạc lại",
  d111:"{feerate}% thuế giao dịch được tính cho mỗi lần chuyển tiền",
  d112: "Nếu bạn có bất kỳ câu hỏi nào, xin vui lòng gửi phản hồi!",
  d113:"Câu hỏi phản hồi",
  d114:"Danh sách phản hồi",
  d115:"Nội dung phản hồi",
  d116:"Dịch vụ khách hàng trực tuyến",
  d117:"Chưa trả lời",
  d118:"Trả lời:",
  d119:"1.Phản hồi",
  d120: "2. Thêm ảnh (tối đa 3 ảnh)",
  d121:"Gửi",
  d122: "Vui lòng tải ảnh lên ở định dạng png hoặc jpg",
  d123: "Đang tải lên, vui lòng đợi...",
  d124: "Kích thước tệp không thể vượt quá 10M",
  d125:"Người dùng",
  d126:"Nhóm khai thác AirDrop CIRCLES",
  d127: "Tổng số tiền phát hành",
  d128:"Đầu ra hiện tại",
  d129:"Trích xuất",
  d130:"Số dư của tôi",
  d131:"Xem",
  d132:"Số người chia sẻ",
  d133: "Máy khai thác đang hoạt động",
  d134:"giờ",
  d135:"fen",
  d136:"thứ hai",
  d137:"Khai thác",
  d138:"Bắt đầu khai thác",
  d139:"Mời bạn bè",
  d140: "Bạn bè bắt đầu khai thác",
  d141:"Nhận lợi ích chung",
  d142:"Chia sẻ cấp 1",
  d143:"địa chỉ",
  d144:"trạng thái",
  d145:"Không còn nữa",
  d146:"Chia sẻ cấp 2",
  d147:"Khai thác sáng tạo",
  d148:"nhập",
  d149: "CIRCLES cam kết xây dựng chuỗi công khai về quyền riêng tư xã hội được mã hóa. Nó dự kiến ​​​​ra mắt vào năm 2025. Tổng số mã thông báo chuỗi công khai là 2 tỷ. Mỗi người dùng có thể nhận được 1T sức mạnh tính toán. Số lượng sẽ giảm dần dần và tổng cộng 80% sẽ được khai thác miễn phí. Thế là xong.",
  d150: "Định giá tổng tài sản",
  d151:"9·18 Vượt qua",
  d152: "Mã thông báo 9·18 là mã thông báo cộng đồng có chủ đề lịch sử đầu tiên được WateKAM hợp tác. Với mục tiêu 'mọi người nắm giữ nó và sẽ không bao giờ quên nó', chúng ta nên ghi nhớ lịch sử và ngăn chặn sự pha loãng. Bắt đầu từ Mã thông báo 9·18 ! Bây giờ hãy tải nó miễn phí!",
  d153:"Quy tắc khai thác",
  d154: "Mỗi người dùng có thể nhận được 1T sức mạnh tính toán. Mỗi T sức mạnh tính toán sẽ tạo ra 30 xu trong 24 giờ đầu. Sức mạnh tính toán sẽ giảm 20% cho mỗi 10 triệu xu được sản xuất.",
  d155: "Lời mời trực tiếp là 50%, lời mời gián tiếp là phần thưởng khuyến khích sức mạnh tính toán 25%!",
  d156: "Nhận airdrop",
  d157: "Việc rút tiền vẫn chưa được mở",
  d158: "Khi bạn liên kết với người dẫn đầu thị trường, bạn có thể nhận được 9,18 918 airdrop token.",
  d159: "Nếu bạn tham gia chia sẻ, cứ 10 người dùng chia sẻ và nhận airdrop, bạn sẽ đủ điều kiện nhận phần thưởng airdrop khác, tối đa là 918!",
  d160:"Airdrop của tôi",
  d161:"Airdrop toàn diện",
  d162:"Chia sẻ của tôi",
  d163: "Việc rút tiền vẫn chưa được mở",
  d164:"Mức độ hiện tại",
  d165:"Xem thu nhập",
  d166:"Người dùng thông thường",
  d167:"Thành viên VIP",
  d168:"Nâng cấp",
  d169:"Sao chép mã lời mời",
  d170:"địa chỉ",
  d171:"trạng thái",
  d172:"sinh thái",
  d173:"Giữ thẻ",
  d174: "Tham gia xây dựng sinh thái và nhận phần thưởng hậu hĩnh",
  d175:"Có thể thu thập ngay hôm nay",
  d176:"Thị trường giao dịch C2C",
  d177:"Mức độ hiện tại",
  d178:"Nhận",
  d179:"nhập",
  d180:"Bạn không có mã mời",
  d181:"Chợ của tôi",
  d182:"Không thể mua",
  d183:"Lãnh đạo của bạn không phải là thành viên",
  d184: "Không đủ cân bằng trên dây chuyền",
  d185: "Cân bằng nền tảng không đủ",
  d186: "Chữ ký không thành công",
  d187: "Chuyển không thành công",
  d188:"Đổi mới",
  d189:"Mã mời",
  d190:"Bạn cần phải trả tiền",
  d191:"Số dư ví",
  d192:"Cân bằng",
  d193:"Cân bằng nền tảng",
  d194: "Đang thanh toán...vui lòng đợi",
  d195:"Thanh toán thành công",
  d196:"Xác nhận thanh toán",
  d197:"Đóng",
  d198:"Thanh toán không thành công",
  d199:"Giao dịch",
  d200:"Kiếm xu",
  d201:"Thả thả",
  d202:"cộng đồng",
  d203:"Trung tâm sinh thái",
  d204:'Chọn mạng',
  d205:"mảnh",
  d206:"Đã nhận",
  d207:"Không nhận được",
  
  h1:'Trang chủ',
  h2:'Nhóm khai thác',
  h3:'Giao dịch',
  h4:'Bệ phóng',
  h5:'Đối tác',
  h6:'Trở thành đối tác của KAM',
  h7:'Tận hưởng tám quyền và lợi ích sinh thái lớn, hãy đến và tham gia! ',
  h8:'Vào',
  h9:'tiền tệ',
  h10:'Số tiền hiện có',
  h11:'Số tiền bị đóng băng',
  h12:'Trên KAM',
  h13:'Giao dịch tiền điện tử',
  h14:'Quy tắc tham gia',
  h15:'Quy tắc tuyển dụng',
  h16:'Việc tuyển dụng đối tác của KAM, tuyển dụng 200 đối tác sáng lập sẽ được thực hiện theo 3 giai đoạn, thể lệ liên quan như sau:',
  h17:'Điều kiện tuyển dụng:',
  h18:'Thanh toán trong thời gian tuyển dụng',
  h19:'Nhận quy tắc:',
  h20:'1: Chia sẻ 5 đối tác hợp lệ và tự động nâng cấp lên đối tác sáng lập, đến trước được phục vụ trước, dự kiến ​​có 200 chỗ cho đối tác sáng lập: phát hành mã thông báo HAPC trước và ánh xạ mã thông báo HAPC sang HAP trước khi lên mạng',
  h21: '30 trong giai đoạn đầu, 16 airdrop mỗi người;',
  h22: '60 trong giai đoạn thứ hai, 11 airdrop mỗi người;',
  h23: '110 ở giai đoạn thứ ba, 6 airdrop mỗi người. ',
  h24: 'Nếu tình hình tốt, chúng ta có thể đưa ra thông báo thêm N số vào giai đoạn thứ tư (kế hoạch) và airdrop n số cho mỗi người',
  h25:'2: Đối tác giới thiệu đối tác có thể hưởng 20% ​​phần thưởng lời mời',
  h26:'3: Đối tác sáng lập mời đối tác có thể nhận được 30% ưu đãi lời mời + 10% ưu đãi lời mời tạo không giới hạn nhóm (không bao gồm các mức bằng nhau)',
  h27: '4. Nếu không có link mời đối tác, bạn không thể nạp tiền để trở thành đối tác, cũng như không thể giới thiệu đối tác',
  h28:'5. Bổ sung phần back-end của hệ thống: đối tác tạo, cài đặt chuyển đổi đối tác. ',
  h29:'Kế hoạch tuyển dụng đối tác',
  h30:'Vấn đề đầu tiên',
  h31:'{round} dấu chấm',
  h32:'Vấn đề thứ ba',
  h33:'Tiến độ tuyển dụng',
  h34:'Thời gian bắt đầu:',
  h35:'Thời gian kết thúc:',
  h36:'Ghế tuyển dụng:',
  h37:'Phí chỗ ngồi:',
  h38:'Số dư tài khoản',
  h39:'Tham gia',
  h40:'Không thể tham gia',
  h41:'Đối tác mới nhất',
  h42:'địa chỉ',
  h43:'Số tiền thanh toán',
  h44:'trạng thái',
  h45:'Đã hoàn thành',
  h46:'Chữ ký không thành công',
  h47:'Nhóm khai thác KAM',
  h48: 'Trong nhóm khai thác KAM, tự do kiếm được ưu đãi cao',
  h49:'Mẫu khởi động HappyDEX đầu tiên',
  h50:'Chưa có thông tin~',
  h51:'Mới trực tuyến',
  h52:'Đến KAM để giao dịch',
  h53:'Hiệu quả cao và nhiều lợi ích',
  h54:'Chuỗi cầu vượt',
  h55:'Người dùng thông thường',
  h56:'Đối tác',
  h57:'Đối tác sáng tạo',
  h58:'Phản hồi về vấn đề',
  h59:'Hoạt động thả rơi',
  h60:'Trung tâm NFT',
  h61:'AI sinh thái',
  h62:'Đúc dòng chữ',
  h63:'Quyền của đối tác',
  h64:'1: Muốn trở thành đối tác thì cấp trên của bạn cũng phải là đối tác',
  h65:'2: Đối tác giới thiệu đối tác có thể nhận được 20% phần thưởng giới thiệu',
  h66:'3: Chia sẻ trực tiếp 5 đối tác và có thể tự động nâng cấp lên "Đối tác sáng tạo", với tối đa 200 chỗ, cho đến khi kết thúc. ',
  h67:'4: Đối tác sáng tạo giới thiệu đối tác có thể nhận được 30% phần thưởng giới thiệu + 10% phần thưởng thị trường thế hệ không giới hạn (không có phần thưởng cho các cấp độ bằng nhau). ',
  h68:'5: Các đối tác sáng lập có thể lấy HAPB và ánh xạ nó tới HAP trước khi lên mạng',
  h69: 'Giai đoạn 1 30 chỗ, airdrop 15/chỗ;',
  h70: 'Giai đoạn 2 60 chỗ, airdrop 10/chỗ;',
  h71: 'Giai đoạn 3 có 110 chỗ, 5 chỗ sẽ được airdrop. ',
  h72:'6: Đối tác có thể nhận được 10 quyền airdrop HAPC mỗi ngày. ',
  h73:'Nhắc nhở ấm áp:',
  h74:'1: Chỗ ngồi của đối tác có hạn và bạn chỉ có thể tham gia trong thời gian tuyển dụng. ',
  h75:'2: Trước khi nhận chỗ, bạn cần đảm bảo tài khoản có đủ số dư và cần chuyển tài sản trong tài khoản. ',
  h76:'Giới thiệu tuyển dụng',
  h77:'Happyswap là sàn giao dịch hợp đồng giao ngay phi tập trung cung cấp cho người dùng trải nghiệm giao dịch an toàn, hiệu quả và tối ưu. Loại bỏ tác động của giá cả và phá vỡ sự độc quyền và kiểm soát nền tảng ban đầu. Người dùng có thể thực hiện nhiều giao dịch tài sản kỹ thuật số và các giao dịch phái sinh tài sản kỹ thuật số khác trên nền tảng HappySwap. Các đối tác của HappySwap được phát hành với số lượng hạn chế trên toàn cầu. Là đối tác, bạn không chỉ được hưởng tám quyền đối tác mà lợi nhuận của toàn bộ nền tảng còn có liên quan chặt chẽ với tất cả các đối tác. Trong kỷ nguyên mới của tài sản kỹ thuật số, chúng tôi sẽ cùng nhau tạo ra một phương thức giao dịch phi tập trung thực sự mới và chia sẻ huyền thoại mười nghìn lần về tài chính kỹ thuật số. ',
  h78:'Số ghế còn lại',
  h79:'Đối tác',
  h80:'Sáng tạo',
  h81:'Bình thường',
  h82:'Hợp tác',
  h83:'Vấn đề này chưa được mở',
  h84:'Tham gia',
  h85:'Thuật ngữ này đã đầy',
  h86:'Vấn đề này đã kết thúc',
  h87:'Trích dẫn',
  h88:'mời',
  h89:'Tài sản',
  h90:'lợi nhuận',
  h91:'hiểu',
  h92:'thông tin KAM',
  h93:'Giá hôm nay',
  h94:'Đi giao dịch',
  h95:'Đã truyền',
  h96:'Đi casting',
  h97:'Tổng số KAM phát hành:',
  h98:'10.000 miếng',
  h99:'KAM lên kế hoạch hủy diệt',
  h100:'Giới thiệu về KAM',
  h101:'KAM, hay Hoán đổi KAM (Tài sản trong thế giới thực), là một nền tảng giao dịch phi tập trung dành riêng cho việc quảng bá tài sản trong thế giới thực trên chuỗi và cung cấp các dịch vụ như ươm tạo thương hiệu, giao dịch lưu thông và hỗ trợ kỹ thuật. ',
  h102: 'Việc mã hóa tài sản thực sẽ giúp các sản phẩm tài chính hoặc vật chất truyền thống mở ra thị trường rộng lớn hơn. Bằng cách hỗ trợ hơn 100 liên minh tài sản, KAM cho phép người dùng tham gia vào các lĩnh vực có triển vọng mạnh mẽ ở các khu vực và ngưỡng. ',
  h103:'Lợi thế KAM',
  h104:'Có kinh nghiệm',
  h105:'Các thành viên cốt lõi của chúng tôi đến từ UNISWAP, MDEX, v.v. và có nhiều kinh nghiệm về nền tảng phi tập trung! ',
  h106:'Tài sản thực',
  h107: 'Dựa vào tài sản thực, token bạn nắm giữ sẽ có giá trị hơn, ổn định hơn và sinh lời nhiều hơn! ',
  h108:'An toàn',
  h109: 'Tất cả các hợp đồng KAM đều là nguồn mở và được kiểm tra đầy đủ. Chúng tôi sẽ từ chối hợp tác với các token không phải là nguồn mở và đã được xem xét! ',
  h110:'Chương trình liên kết',
  h111: 'Tham gia câu lạc bộ cung cấp khả năng thu nhập theo nhiều cơ chế khác nhau dựa trên cùng một mạng lưới thị trường và trở thành đối tác giàu có ổn định và đáng tin cậy của bạn! ',
  h112:'Lộ trình',
  h113:'Quý 2 năm 2022',
  h114:'Thành lập dự án sản phẩm và chuẩn bị phát triển chuỗi công cộng',
  h115:'Quý 4 năm 2023',
  h116:'Thị trường KAM và các dịch vụ thành viên đang mở',
  h117:'Quý 2 năm 2024',
  h118:'KAM ra mắt và chiêu mộ câu lạc bộ',
  h119:'Thành viên',
  h120:'Quý 3 năm 2024',
  h121:'Xây dựng kế hoạch hơn 100 tài sản liên minh',
  h122: 'Lập kế hoạch, quảng bá các dịch vụ trên chuỗi tài sản thực tế',
  h123:'Quý I năm 2025',
  h124:'SocialFi trực tuyến, cung cấp nhiều chuỗi',
  h125:'Dịch vụ Ví Bảo mật Xã hội',
  h126:'Quý II năm 2025',
  h127: 'Chính thức ra mắt chuỗi công khai và ra mắt',
  h128:'Kế hoạch phát triển mạng lưới vũ trụ Đông Nguyên',
  h129:'Trung tâm sinh thái',
  h130:'phổ biến',
  h131:'mới nhất',
  h132:'chính thống',
  h133:'Hãy theo dõi',
  h134:'',
  h135:'',
  h136:'',
  h137:'',
  h138:'',
  h139:'',
  h140:'',
  h141:'',
  h142:'',
  h143:'',
  h144:'',
  h145:'',
  h146:'',
  h147:'',
  h148:'',
  h149:'',
  h150:'',
  h151:'',
  h152:'',
  h153:'',
  h154:'',
  h155:'',
  h156:'',
  h157:'',
  h158:'',
  h159:'',
  
  s101:'Sức mạnh tính toán được tạo ra',
  s102:'Thu nhập sức mạnh tính toán ngày nay',
  s103:'Chia sẻ lợi nhuận ngày hôm nay',
  s104:'Hóa đơn',
  s105:'Xem',
  s106:'Năng lượng hiệu quả cá nhân',
  s107:'Năng lượng cộng đồng tối đa',
  s108:'Năng lượng cộng đồng khác',
  s109:'Danh sách rồng hổ',
  s110:'Giá trị năng lượng:',
  s111:'Giá trị công suất tính toán:',
  s112:'Trao đổi',
  s113:'Không mở',
  s114:'Sản xuất:',
  s115:'Để được xuất ra:',
  s116:'Tiến trình đầu ra',
  s117: 'Bạn cần phải trả tiền',
  s118:'Số dư tài khoản',
  s119:'Cân bằng',
  s120:'Xác nhận thanh toán',
  s121:'cửa hàng NFT',
  s122:'Sản xuất',
  s123:'Kiệt sức',
  s124:'Mời bạn bè',
  s125:'Mua NFT',
  s126:'Nhận được lợi ích chung',
  s127:'địa chỉ',
  s128:'Năng lượng cá nhân',
  s129:'Năng lượng đồng đội',
  s130:'Thu nhập năng lượng tính toán',
  s131:'Đã nhận',
  s132:'Sẽ được thu thập',
  s133:'Nhận',
  s134:'Chia sẻ lợi nhuận',
  s135:'Danh sách rồng hổ',
  s136:'thời gian',
  s137:'Số lượng',
  s138:'tiền tệ',
  s139:'Tính hóa đơn tiền điện',
  s140:'Chia sẻ hóa đơn',
  s141:'Dự luật danh sách rồng và hổ',
  s142:'Thời gian swap',
  s143:'Sản xuất',
  s144:'Kiệt sức',
  s145:'Trung tâm NFT',
  
  
  s146: "Tham gia sức mạnh tính toán",
  s147: "đạt được lợi ích",
  s148: "Sức mạnh tính toán cá nhân",
  s149: "Sức mạnh tính toán của nhóm",
  s150: "Sức mạnh tính toán cộng đồng tối đa",
  s151: "Sức mạnh tính toán cộng đồng khác",
  s152:"Quy tắc",
  s153: "KAM đốt cháy sức mạnh tính toán. Ngoài quà tặng sáng tạo, một tài khoản có thể đúc tới 10T. Loại tiền và số lượng dùng để đúc sẽ bị đốt và tiêu hủy để giảm lưu thông. Mỗi T có giá trị trong 365 ngày!",
  s154: "Sức mạnh tính toán hiệu quả",
  s155:"Thu nhập cộng đồng ngày nay",
  s156: "Toàn bộ mạng nắm giữ sức mạnh tính toán",
  s157: "Chia sẻ sức mạnh tính toán trên toàn bộ mạng",
  s158:"Công cụ khai thác liên kết hợp lệ",
  s159: "Sức mạnh tính toán cộng đồng tối đa",
  s160: "Sức mạnh tính toán cộng đồng khác",
  s161: "Chọn sức mạnh tính toán",
  s162:"Phương pháp đốt",
  s163:"truyền",
  s164: "Không thể truyền được",
  s165:"tất cả",
  s166: "Đang tiến hành",
  s167:"Đã kết thúc",
  s168: "Vui lòng chọn phương thức thanh toán",
  s169:"Không thanh toán",
  s170: "Khuyến khích sức mạnh tính toán",
  s171:"Khuyến khích cộng đồng",
  s172: "Ưu đãi giữ mã thông báo",
  s173: "Khuyến khích nút",
  s174:"Hãy chia sẻ",
  s175:"giữ tài khoản",
  s176: "KAM là ví đa chuỗi xã hội được mã hóa từ đầu đến cuối WEB 3.0 dựa trên Kademlia làm giao thức cơ bản: mã thông báo nền tảng gốc của Ví Kami.",
  s177: "Tổng nguồn cung KAM là 130 triệu và số phát hành ban đầu là 1,8 triệu. Phần còn lại được sản xuất thông qua sự phá hủy để tạo ra sức mạnh tính toán và khai thác, tức là lưu thông bên, phát hành bên và phá hủy bên, với cốt lõi là xây dựng cấu trúc liên minh để hỗ trợ nhiều liên minh Khai thác phá hủy kết hợp tiền xu ",
  s178: "Nhóm Kademlia đến từ các nhóm cốt lõi của Google, Oracle, UNI và các nhóm khác và có nhiều kinh nghiệm trong lĩnh vực xã hội mã hóa.",
  s179:"Trò chuyện thoải mái",
  s180: "Kami Wallet áp dụng giải pháp liên lạc được mã hóa hai đầu ẩn danh, có thể đạt được các nhu cầu xã hội hiệu quả hơn, an toàn hơn, riêng tư hơn và thuận tiện hơn.",
  s181:"Ổn định lâu dài",
  s182: "Nhóm khai thác KAM áp dụng giải pháp sức mạnh tính toán cụ thể và kinh nghiệm đã chứng minh rằng nó có thể thúc đẩy hiệu quả sự phát triển bền vững, ổn định lâu dài và tăng giá trị ổn định.",
  s183: "Nhóm khai thác sức mạnh điện toán KAM hỗ trợ khai thác kết hợp các loại tiền tệ liên minh để thúc đẩy hợp tác đa phương, phát triển chung và đôi bên cùng có lợi.",
  s184:"Quý đầu tiên của năm 2024",
  s185: "Thành lập dự án sản phẩm và trình diễn và chuẩn bị công việc liên quan",
  s186:"Quý 3 năm 2024",
  s187: "Nhóm khai thác sức mạnh tính toán KAM ra mắt cấp độ Kami",
  s188: "Phiên bản Ví Beta 1.0 đang trực tuyến",
  s189:"Quý 4 năm 2024",
  s190: "Thúc đẩy nhiều kế hoạch hợp tác liên minh,",
  s191: "Đạt được sự tích hợp tài nguyên nhanh chóng.",
  s192:"Quý đầu năm 2025",
  s193: "Thúc đẩy xây dựng sinh thái KAM,",
  s194: "Cải thiện mạng kịch bản ứng dụng KAM",
  s195:"Quý 2 năm 2025",
  s196: "Thúc đẩy KAM đạt được các giao dịch hàng đầu",
  s197: "Nền tảng để đạt được toàn cầu hóa hơn nữa",
  s198: "Phát triển văn hóa.",
  s199:"Đối tác",
  s200: "Nhóm khai thác năng lượng tính toán",
  s201:"Công cụ khai thác cộng đồng",
  s202:"Nút cộng đồng",
  s203:"Người dùng thông thường",
  s204:"nút",
  s205:"thợ mỏ",
  s206: "Định giá tổng tài sản",
  s207: "Thu nhập hôm nay",
  s208:"Tài sản",
  s209: "Ẩn nội dung bằng 0",
  s210:"tiền tệ",
  s211:"Có sẵn",
  s212: "Đang rút tiền",
  s213:"Thời gian hết hạn",
  s214: "Tiến trình lưu thông",
  s215:"Đúc thành công",
  s216:"",
  s217:"",
  s218:"",
  s219:"",
  s220:"",
  s221:"",
  s222:"",
  s223:"",
  s224:"",
  s225:"",
  s226:"",
  };
  