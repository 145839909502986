import request from '../utils/request';
export default {
    //获取地址
    postaddress(data,address,language) {
        return request.request({
            url: '/wallet/get-address',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           data:data
            
        })
    },
    
    rechargereceiver(address) {
        return request.request({
            url: '/recharge/receiver',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    //入账标记
    postruzhuang(data,address,language) {
        return request.request({
            url: '/wallet/entry-mark',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           data:data
            
        })
    },
    //用户钱包信息
    getqianbao(address,language) {
        return request.request({
            url: '/wallet/list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
         
            
        })
    },
       //用户单个钱包信息
       getqianbaoone(data,address,language) {
        return request.request({
            url: '/wallet/info',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           params:data
            
        })
    },
     //今日收益
     getshoyi(data,address,language) {
        return request.request({
            url: '/wallet/today-reward',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           params:data
            
        })
    },
      //记录列表
      getjiluliebiao(data,address,language) {
        return request.request({
            url: '/wallet/record/list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           params:data
            
        })
    },
     //行为条件
     getxingwei(address,language) {
        return request.request({
            url: '/wallet/behaviors',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           
            
        })
    },
     //链列表
     getlianlist(address,language) {
        return request.request({
            url: '/wallet/chain/list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           
            
        })
    },
      //链代币
      getliantoken( data,address,language) {
        return request.request({
            url: '/wallet/chain/list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           params:data
            
        })
    },
      //通过币key获取钱包余额
      getwalletgetinfo( data,address,language) {
        return request.request({
            url: '/wallet/info',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           params:data
            
        })
    },
    walletkam(address,language) {
        return request.request({
            url: '/wallet/kam',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    
}