// import addresses from '@/config/constants/contracts.js';
const addresses = {
  jackRouter: '0x22A7813978f5589c6fBa94983Be7169Eb796B3F2',
  jackFactory: '0x5CD2a6e2531b14FFDc005C642D2ACc2159131B27',
  weth: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
};

export const getAddress = address => {
  return address;
};

export const getJackFactoryAddress = () => {
  return getAddress(addresses.jackFactory);
};

export const getJackRouterAddress = () => {
  return getAddress(addresses.jackRouter);
};

export const getWethAddress = () => {
  return getAddress(addresses.weth);
};
