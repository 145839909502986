<template>
    <div class="chobi">
        <div class="chozhi">

            <div class="tonzhen" @click="tonzhenfunis">
                <span>{{ stokenname }}</span>
                <van-icon name="arrow-down" />
            </div>
            <van-popup v-model="tonzhenshow" round position="bottom" class="vantonzhenshow">
                <div class="t_li" v-for="item in tokens" @click="seclettokenfun(item)">
                    <img :src="`${imgurl}${item.logo}`" alt="">
                    <span> {{ item.token }}</span>
                </div>

                <div class="quxiao">
                    <span @click="tonzhenfunis">{{ $t('lang.swap154') }}</span>
                </div>
            </van-popup>
            <p class="c_shul">{{ $t('lang.d22') }}</p>
            <van-field type="number" v-model="toInput" input-align="left" :placeholder="`${$t('lang.d23')} `"
                class="van-field" />
            <p class="balance" v-show="balance">{{ $t('lang.d24') }}{{ balance }}</p>
            <p class="c_shul">{{ $t('lang.d25') }}</p>

            <div class="tonzhen ruzhang">
                <span>{{ addressshow }}</span>
                <div class="r_line"></div>
                <img src="@/assets/imgsnowball/copy.png" alt="" @click="copy" :data-clipboard-text="ruzhangaddress"
                    class="copys">
            </div>
            <P style="font-size: 14px;">
            <div>{{ $t('lang.d99') }}</div>
            <div>{{ $t('lang.d110') }}</div>
            </P>

            <div class="c_button" v-if="showbutton" @click="sendtranform">

                <van-loading type="spinner" color="#313235" v-if="loading" />
                <span v-else>{{ $t('lang.d26') }}</span>
            </div>
            <div class=" zhihui" v-else>{{ $t('lang.d26') }}</div>
        </div>
        <p class="c_jilu">{{ $t('lang.d27') }}</p>
        <div class="c_wlist" v-if="chobilist.length == 0">
            <img src="@/assets/imgsnowball/wujilu.png" alt="">
            <p>{{ $t('lang.d57') }}</p>
        </div>
        <div class="c_list" v-else>
            <div class="li" v-for="item in chobilist">
                <div>
                    <div class="c_head">{{ item.behavior_txt }}{{ item.token_name }}</div>
                    <div> {{ item.created_time }}</div>
                </div>
                <span style="color: #39DBA3;">{{ item.usable_change }}</span>
            </div>

        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { getErc20Contract } from '@/utils/contractHelp';
import { bizhong, homedata, moneymask } from '@/api/index'
import Clipboard from 'clipboard';
import Web3 from 'web3';
import erc20 from '@/config/abi/erc20.json'
export default {
    data() {
        return {
            imgurl: "",
            tonzhenshow: false,
            toInput: "",
            value1: 0,
            tokens: [],
            stokenname: this.$t('lang.d21'),
            stokenaddress: "",
            tokenId: "",
            ruzhangaddress: "",
            addressshow: "",
            balance: "",
            invite_link: "",
            loading: false,
            chobilist: [],
            chainParentId: {},
            TokenItem:{}
        };
    },
    created() {

        this.init()

    },
    computed: {
        ...mapState(['account', 'lang', 'NetItem']),
        showbutton() {
            return this.stokenname != this.$t('lang.d21') && !!this.toInput;
        },

    },
    mounted() {
    },
    watch: {
        account() {
            this.init();
            // this.finished = false;
        },
        lang() {
            this.init();
            if (this.stokenname != this.$t('lang.d21')) {
                this.stokenname = this.$t('lang.d21')
            }
        }
    },
    methods: {
        init() {
            this.gettokenconfigs()
            this.getimgurl()
            this.getpostaddress()
        },
        copy() {

            this.invite_link = this.account;
            if (this.invite_link) {
                var clipboard = new Clipboard('.copys');
                clipboard.on('success', e => {
                    this.$toast(this.$t('lang.swap300'));
                    clipboard.destroy(); // 释放内存
                });
                clipboard.on('error', e => {
                    this.$toast(this.$t('lang.swap301'));
                    clipboard.destroy(); // 释放内存
                });
            }
        },
        tonzhenfunis() {
            this.tonzhenshow = !this.tonzhenshow;
        },
        gettokenconfigs() {
            bizhong.gettokenrechargeconfigs(this.account).then(res => {

                this.tokens = res.data;
            })
            let data = {
                page: 1,
                page_size: 10,
                type_arr: "[0]"
            }
            moneymask.getjiluliebiao(data, this.account).then(res => {
                if (res.data.list.length == 0) {
                    return
                } else {
                    this.chobilist = res.data.list
                }


            })
        },

        getimgurl() {
            homedata.gettokenimgurl().then(res => {
                this.imgurl = res.data.image_domain
            })
        },
        async getpostaddress() {
            moneymask.rechargereceiver(this.account).then(res => {
                if (res.code == 200) {
                    if (res.data.receiver == "" || res.data.receiver == null) {
                        this.addressshow = this.$t('禁止充币')
                    } else {
                        this.ruzhangaddress = res.data.receiver
                        const head = this.ruzhangaddress.slice(0, 4);
                        const tail = this.ruzhangaddress.slice(this.ruzhangaddress.length - 4);
                        this.addressshow = head + "****" + tail;
                    }

                }

            })
        },
        seclettokenfun(item) {
            this.tonzhenshow = false;
            let chain_list = item.chain_list;
            let isexist = false
            for (let Dataitem of chain_list) {
                if (Dataitem.chain == this.NetItem.name) {
                    this.chainParentId = Dataitem
                    isexist = true
                }
            }
            if (!isexist) {
                this.$toast(this.$t('暂不支持' + this.NetItem.name + '链'));
                return;
            }
            this.stokenname = item.token + `(${this.NetItem.name})`;
            this.TokenItem  = item
            this.stokenaddress = this.chainParentId.contract;
            this.tokenId = this.chainParentId.token_id;

            if (item.token == "BNB" || item.token == "KAD") {
                this.getbanceof()
            } else {
                this.geterc20banceof()
            }

        },
        async sendtranform() {
            if (this.toInput <= this.balance) {
                if (this.toInput <= 0) {
                    this.$toast(this.$t('lang.d79'));
                } else {
                     if(this.TokenItem.token == "BNB" || this.TokenItem.token == "KAD"){
                         this.SendETH()
                     }else{
                        this.sendf()
                     }
                  
                }

            } else {
                this.$toast(this.$t('lang.d80'));
            }

        },
        async SendETH() {
            this.loading = true
            let web3 = new Web3(window.ethereum);
            const amount = web3.utils.toWei(this.toInput, 'ether')
            web3.eth.sendTransaction({
                from: this.account,
                to: this.ruzhangaddress,
                value: amount
            }).on("receipt", (res) => {

                this.$toast(this.$t('lang.d81'));
                this.init()
                this.getbanceof()
                this.loading = false
            }).on('transactionHash', transactionHash => {
                let data = {
                    tx_hash: transactionHash,
                    chain: this.NetItem.name
                }
                moneymask.postruzhuang(data, this.account).then(res => {
                    this.$toast(res.msg)
                })


            }).on('error', (error, receipt) => {

                this.$toast(this.$t('lang.d82'));
                this.loading = false

            })
        },
        async sendf() {
            this.loading = true

            let web3 = new Web3(window.ethereum);
            const erc20Contract = new web3.eth.Contract(erc20, this.stokenaddress)
            const amount = web3.utils.toWei(this.toInput, 'ether')
            const gas = await erc20Contract.methods.transfer(this.ruzhangaddress, amount).estimateGas({ from: this.account })
            erc20Contract.methods.transfer(this.ruzhangaddress, amount).send({ from: this.account, gas }).on("receipt", (res) => {

                this.$toast(this.$t('lang.d81'));
                this.init()
                this.geterc20banceof()
                this.loading = false
            }).on('transactionHash', transactionHash => {
                let data = {
                    tx_hash: transactionHash,
                    chain: this.NetItem.name
                }
                moneymask.postruzhuang(data, this.account).then(res => {
                    this.$toast(res.msg)
                })


            }).on('error', (error, receipt) => {

                this.$toast(this.$t('lang.d82'));
                this.loading = false

            })

        },
        async getbanceof() {
            let web3 = new Web3(window.ethereum);
            this.balance = await web3.eth.getBalance(this.account)
            this.balance = web3.utils.fromWei(this.balance, 'ether')
            this.balance = parseFloat(this.balance).toFixed(3)
        },
        async geterc20banceof() {
            let web3 = new Web3(window.ethereum);
            const erc20Contract = getErc20Contract(this.stokenaddress);
            this.balance = await erc20Contract.methods.balanceOf(this.account).call();
            this.balance = web3.utils.fromWei(this.balance, 'ether')
            this.balance = parseFloat(this.balance).toFixed(3)
        }

    },
};
</script>

<style lang="less" scoped>
.chobi {
    padding: 20px;
    width: 100%;
    background: rgba(16, 20, 32, 1);
    border-radius: 20px;
}

.chozhi {
    width: 100%;
    padding: 15px 20px;

    border-radius: 20px;
    background: rgba(25, 29, 41, 1);

    .tonzhen {
        width: 100%;
        height: 52px;
        border-radius: 10px;
        background: rgba(16, 20, 32, 1);
        padding: 15px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
            width: 10px;
            height: 10px;

        }

        span {
            color: #fff;
            font-size: 14px;
            font-weight: 700;
            font-family: "PingFang";
        }
    }

    .vantonzhenshow {
        padding: 20px;

        .t_li {
            height: 50px;
            width: 100%;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #0000000f;

            img {
                width: 24px;
                height: 24px;
                margin-right: 15px;
                border-radius: 50%;
            }
        }

        .quxiao {
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: center;
            margin-top: 20px;
            color: #00000066;
            font-size: 16px;
            font-weight: 500;
            font-family: "PingFang SC";
        }
    }

    .c_shul {
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        font-family: "PingFang";
        margin-top: 30px;
    }

    .van-field {
        width: 100%;
        border-radius: 10px;
        font-weight: bold;
        background: rgba(16, 20, 32, 1) !important;
        padding: 16px 20px !important;

        /deep/ .van-field__control {
            font-size: 18px;
            color: #9395A4;
        }
    }

    .balance {
        color: #9395a4ff;
        font-size: 12px;
        font-weight: 700;
        font-family: "PingFang";
    }

    .ruzhang {
        position: relative;

        span {
            color: #fff;
            font-size: 14px;
            font-weight: 700;
            font-family: "PingFang";
        }

        .r_line {
            height: 15px;
            width: 1px;
            background: #56A0B7;
            position: absolute;
            right: 60px;
            top: 20px;
        }

        img {
            width: 20px;
            height: 20px;
        }
    }

    .c_button {
        margin-top: 30px;
        width: 100%;
        height: 55px;
        border-radius: 10px;
        opacity: 1;
        background: rgba(178, 87, 247, 1);
        box-shadow: 0 20px 30px 0 #191c321a;
        text-align: center;
        line-height: 55px;
        color: #000;
        font-size: 16px;
        font-weight: 700;
        font-family: "PingFang";
    }
}

.c_jilu {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    font-family: "PingFang";
    margin-top: 30px;
}

.c_wlist {
    width: 100%;
    height: 219px;
    border-radius: 20px;
    opacity: 1;
    background: rgba(25, 29, 41, 1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 122px;
        height: 92px;

    }

    p {
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        font-family: "PingFang";
    }
}

.c_list {
    width: 100%;
    background: rgba(25, 29, 41, 1);
    padding: 15px 18px;
    border-radius: 20px;

    .li {
        height: 40px;
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        div {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .c_head {
                color: #fff;
                font-size: 14px;
                font-weight: 700;
                font-family: "PingFang";
            }

            .c_time {
                color: #848484;
                font-size: 12px;
                font-weight: 500;
                font-family: "PingFang";
            }
        }

        span {
            color: #24E7A3;
            font-size: 14px;
            font-weight: 700;
            font-family: "PingFang";
        }
    }
}

.zhihui {
    margin-top: 30px;
    width: 100%;
    height: 55px;
    border-radius: 10px;
    opacity: 0.6;

    text-align: center;
    line-height: 55px;
    color: #000;
    font-size: 16px;
    font-weight: 700;
    font-family: "PingFang";
    background: rgba(178, 87, 247, 1);
}

.op {
    opacity: 0.4;
}
</style>