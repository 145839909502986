<template>
  <div style=" background:rgba(10, 13, 19, 1); padding-top: 20px; min-height: 100vh;">
    <div style="display: flex; align-items: center;margin-left: 20px;">
      <topbutton router="snowguan" style="margin-right: 20px;"></topbutton>
      <p class="s_title">{{ $t('lang.d86') }}</p>
    </div>
    <div class="shoyi">


      <div class="s_boxs">
        <div class="b_title">
          <span>{{ $t('lang.d87') }}</span>
          <span >{{ $t('lang.d88') }}</span>
          <span>{{ $t('lang.d89') }}</span>
          <span>{{ $t('lang.d90') }}</span>
        </div>
        <div class="c_wlist" v-if="shoyilist.length == 0">
          <img src="@/assets/imgsnowball/wujilu.png" alt="">
          <p>{{ $t('lang.d57') }}</p>
        </div>
        <div v-else >
          <van-list v-model="loading" :finished="finished" :finished-text="`${$t('lang.d145')}`" @load="onLoad"
            class="boxx">
            <div v-for="item in shoyilist" class="boxs" style="text-align: center;">
              <div style="flex: 1; text-align: left;">{{ item.created_time }}</div>
          <span style="flex: 1;">{{ item.type_txt }}</span>
          <span style="flex: 1;">{{ item.usable_change >0 ? '+' + item.usable_change : item.usable_change }}</span>
          <span style="flex: 1; text-align: right;">{{ item.token }}</span>
            

            </div>
          </van-list>
          <!-- <div v-for="item in shoyilist" class="boxs" >
          <div style="width: 30%; flex-shrink: 0;">{{ item.created_time }}</div>
          <div style="color: #fff;">{{item.behavior_txt}}</div>
          <div style="color: #39dba3ff;" v-if="item.usable_change>=0">＋{{ item.usable_change }}</div>
          <div style="color: red;" v-else>{{ item.usable_change }}</div>
          <div style="color: #999999;">{{ item.token_name}}</div>
          
        </div> -->
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import { mapState } from 'vuex';
import { moneymask } from '@/api/index';
import topbutton from '@/components/topbutton.vue';
export default {
  name: '',
  components: {
    topbutton
  },
  data() {
    return {
      shoyilist: [],
      loading: false,
      finished: false,
      page: 1,
      page_size: 10,
      isrun: 0
    }
  },
  computed: {
    ...mapState(['account', 'lang']),
  },
  created() {
    this.getshoyi()
  },
  watch: {
    account() {
      //获取账号
      this.getshoyi();
    },
    lang() {
      this.getshoyi();
    }
  },
  methods: {
    getshoyi() {

      let data = {
        page: 1,
        page_size: this.page_size,
        behavior_arr: '[-1]',
        token_id: 0
      }
      moneymask.getjiluliebiao(data, this.account).then(res => {
        console.log(res);
        if (this.page == 1) {
          this.shoyilist = res.data.list
        } else {
          this.shoyilist = [...this.shoyilist, ...res.data.list]
        }

        if (res.data.list.length < 10) {
          this.finished = true
          this.loading = true
        }

      })
    },
    onLoad() {
      this.page++
      this.getshoyi()

    },
  },
}
</script>

<style lang="less" scoped>
.shoyi {
  width: 100%;
  background: rgba(10, 13, 19, 1);
  padding: 20px;
  height: 100%;
  border-radius: 20px 20px 0 0;
}

.s_title {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  font-family: "PingFang";


}

.s_boxs {
  width: 100%;

  border-radius: 20px;
  box-sizing: border-box;
  padding: 16px;

  background: rgba(25, 29, 41, 1);
  border: 1px solid rgba(36, 40, 52, 1);

  div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .b_title {
    color: #999999ff;
    font-size: 14px;
    font-weight: 500;
    font-family: "PingFang";
  }

  .b_cont {
    color: #999999ff;
    font-size: 14px;
    font-weight: 700;
    font-family: "PingFang";
  }
}

.boxx {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 200px;
  max-height: 400px;
  overflow-y: scroll;
  .boxs{
    width: 100%;
    &>div{
      width: 25% !important;
      
    }
  }
}

.c_wlist {
  width: 100%;
  height: 219px;
  border-radius: 20px;
  opacity: 1;

  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 122px;
    height: 92px;

  }

  p {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    font-family: "PingFang";
  }
}
</style>