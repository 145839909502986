import request from '../utils/request';
export default {
    //购买
    nftholdbuy(data,address) {
        return request.request({
            url: '/nft/hold/buy',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           data:data
        })
    },
     //领取
     nftholdclaim(data,address) {
        return request.request({
            url: '/node/wallet/claim',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           data:data
        })
    },
      //持有列表
      nftholdlist(data,address) {
        return request.request({
            url: '/node/cp/hold-list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           params:data
        })
    },
     
      //账单列表
      nftwalletrecord(data,address) {
        return request.request({
            url: '/node/wallet/record',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           params:data
        })
    },
     //资产
     nftwalletinfo(address) {
        return request.request({
            url: '/node/wallet/info',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            }, 
        })
    },
    nftlist(address) {
        return request.request({
            url: '/nft/list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            }, 
        })
    },
    nftrankinglist(address) {
        return request.request({
            url: '/nft/ranking/list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            }, 
        })
    },
    nftsummaryinfo(address) {
        return request.request({
            url: '/node/user/summary',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            }, 
        })
    },
    walletinfobykey(data,address) {
        return request.request({
            url: '/wallet/info-by-key',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           params:data
        })
    },
    nftchildrenlist(data,address) {
        return request.request({
            url: '/node/user/children-list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           params:data
        })
    },
    nodeuserteaminfo(address) {
        return request.request({
            url: '/node/user/team-info',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    nodecprounds(address) {
        return request.request({
            url: '/node/cp/rounds',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    nodecpbuy(data,address) {
        return request.request({
            url: '/node/cp/buy',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           data:data
        })
    },
}