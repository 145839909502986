<template>
  <div class="assets">
    <topbutton router="snowguan" style="margin-bottom: 20px;"></topbutton>
    <div class="assets_card">
      <div class="assets_card_title">
        <img src="@/assets/img/rswap/assets_card.png" alt="" />
        <span>{{ $t('lang.s206') }}</span>
      </div>
      <div class="assets_card_sum">$ {{ assetsSum }}</div>
     
    </div>
    <div class="RouterList">
        <div class="RouterList_item" v-for="(item,index) in routerlist" :key="index" @click="bNav(item.router,item.i)">
            <img :src="item.image" alt="">
            <span>{{ $t(item.name) }}</span>
        </div>
    </div>
    <div class="assets_title">
       
            <span style="font-size: 18px; margin-right: 13px; font-weight: bold;">{{ $t('lang.s208') }}</span>
            <van-checkbox v-model="checked" shape="square" icon-size="12px" style="color: #fff;">{{ $t('lang.s209') }}</van-checkbox>
       
    </div>
    <div class="list">
        <div class="list_li">
            <div class="list_li_title">{{ $t('lang.s210') }}</div>
            <div class="list_li_title">{{ $t('lang.s211') }}</div>
            <div class="list_li_title">{{ $t('lang.s212') }}</div>
          
        </div>
        <template v-if="!checked">
          <div class="list_li" v-for="(item,index) in zhicanlist" :key="index">
            <div class="list_li_item" style="flex-direction: row;">
                <img :src="tokenimgurls+item.logo" alt="">
                <div style="font-size: 14px; color: #fff;">{{item.token}}</div>
            </div>
            <div class="list_li_item">
               <span>{{ item.usable }}</span>
                <span>${{ (item.usable*item.usd_price).toFixed(2) }}</span>
            </div>
            <div class="list_li_item" style="text-align: right;">
               <span style="color: rgba(152, 232, 110, 1);">{{ item.freeze }}</span>
                <span>${{ (item.freeze*item.usd_price).toFixed(2) }}</span>
            </div>
          
          
        </div>
      </template>
      <template v-else>
          <div class="list_li" v-for="(item,index) in zhicanlist1" :key="index">
            <div class="list_li_item" style="flex-direction: row;">
              <img :src="tokenimgurls+item.logo" alt="">
                <div style="font-size: 14px; color: #fff;">{{item.token_name}}</div>
            </div>
            <div class="list_li_item">
               <span>{{ item.usable }}</span>
                <span>$ {{ (item.usable*item.usd_price).toFixed(2) }}</span>
            </div>
            <div class="list_li_item" style="text-align: right;">
               <span style="color: rgba(152, 232, 110, 1);">{{ item.freeze }}</span>
                <span>${{ (item.freeze*item.usd_price).toFixed(2) }}</span>
            </div>
        
          
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { moneymask,homedata } from '@/api';
import topbutton from '@/components/topbutton.vue';
export default {
  data() {
    return {
        routerlist: [
        {
          image: require('@/assets/imgsnowball/chobi.png'),
          image1: require('@/assets/imgsnowball/chobi1.png'),
          name: 'lang.d1',
          router: 'ChoBi1',
          i: '0',
          IsOpen: true,
        },
        {
          image: require('@/assets/imgsnowball/tibi.png'),
          image1: require('@/assets/imgsnowball/tibi1.png'),
          name: 'lang.d2',
          router: 'TiBi2',
          i: '1',
          IsOpen: true,
        },
        
        {
          image: require('@/assets/imgsnowball/zhuanzhang.png'),
          image1: require('@/assets/imgsnowball/zhuanzhang1.png'),
          name: 'lang.d3',
          router: 'ZhuangZhan3',
          i: '2',
          IsOpen: true,
        },
        // {
        //   image: require('@/assets/imgsnowball/shandui.png'),
        //   image1: require('@/assets/imgsnowball/shandui.png'),
        //   name: 'lang.swap17',
        //   router: 'shandui',
        //   i: '2',
        //   IsOpen: true,
        // },
        {
          image: require('@/assets/imgsnowball/zhangdan.png'),
          image1: require('@/assets/imgsnowball/zhangdan.png'),
          name: 'lang.d4',
          router: 'zhangdan',
          IsOpen: true,
        },
      ],
      zhicanlist:[],
      zhicanlist1:[],
      checked:false,
      assetsSum:0.00,
      tokenimgurls:'',
      assetshoyi:0.00,
      documentLoading:false

    };
  },
  computed: {
    ...mapState(['account', 'lang']),
  },
  components: {topbutton},
  mounted() {},
  created(){
    this.getimgurl()
this.getzichan()
  },
  watch:{
      account(){
        this.getimgurl()
        this.getzichan()
      
      }
  },
  methods: {
    bNav(router,i = '') {
     
     if (router != '') {
           if(i!= ''){
             this.$router.push({ name: router ,query: { id: i }},);
             
           }else{
             this.$router.push({ name: router });
           }
         
       
     } else {
       // this.$toast(this.$t('lang.swap65'));
     }
   },
   getassetsshoyi() {
            let data = {
              
            }
            moneymask.getshoyi(data, this.account).then(res => {
                console.log(res);
                this.assetshoyi = res.data
            })
        },
   getimgurl() {
            homedata.gettokenimgurl().then(res => {
                this.tokenimgurls = res.data.image_domain;
            })
        },
   getzichan() {
            moneymask.getqianbao(this.account).then(res => {
                 if(res.code != 200) return ;
                this.zhicanlist = res.data 
                 for(let item of  this.zhicanlist){
                
                     this.assetsSum += parseFloat((item.usable*item.usd_price).toFixed(2));
                        if(item.usable>0){
                          this.zhicanlist1.push(item)
                        }
                 }

        
            })
        },
       
  },
};
</script>
<style scoped lang='less'>
/deep/.van-checkbox__label{
    color: rgba(153, 153, 153, 1);
    font-size: 12px;
}
.assets {
  padding: 70px 20px;
}
.list{
    width: 100%;
    .list_li{
        padding-bottom: 12px;
        padding-top: 14px;
        border-bottom: 1px solid rgba(38, 41, 53, 1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .list_li_title{
          width: 25%;
          text-align: center;
          font-size: 12px ;
          font-weight: 700;
          color: rgba(153, 153, 153, 1);
        }
        .list_li_item{
        

            width: 25%;
            display: flex; 
            flex-direction: column;
            align-items: center;
           img{
            border-radius: 50%;
            width: 20px;
            height: 20px;
             margin-right: 7px;
           }
           &>span:first-child{
            color: #fff;
           }
           &>span:last-child{
            transform: scale(0.8);
            font-size: 12px;
            color: rgba(255, 255, 255, 0.6);
           }
        }
    }
}
.assets_title{
    display: flex;
    color: #fff;
    align-items: center;
    margin: 30px 0 8px 0;
}
.RouterList{
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .RouterList_item{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 700;
        color: rgba(255, 255, 255, 1);
        img{
            width: 19px;
height: 19px;
margin-bottom: 7px;
        }
    }
}
.assets_card {
  padding: 16px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid rgba(36, 40, 52, 1);
  height: 131px;
  width: 100%;
  .assets_card_income{
    font-size: 12px;
    color: #fff;
    margin-bottom: 10px;
  }
  .assets_card_sum {
    font-size: 30px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
  }
  .assets_card_title {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 12px;

    img {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
  }
}
</style>