export const lang = {
  swap1: '閃兌',
  swap2: '立刻開始閃兌',
  swap3: '從',
  swap4: '餘額:',
  swap5: '選擇幣種',
  swap6: '最大',
  swap7: '價格',
  swap8: '滑點容差',
  swap9: '連接錢包',
  swap10: '輸入數量',
  swap11: '餘額不足',
  swap12: '此交易的流動性不足',
  swap13: '選擇代幣',
  swap14: '授權',
  swap15: '包裝',
  swap16: '解包',
  swap17: '閃兌',
  swap18: '交易收據',
  swap19: '正在等待確認',
  swap20: '正在將',
  swap21: '交換為',
  swap22: '在你的錢包種確認此交易',
  swap23: '已提交交易',
  swap24: '在BSC上查看',
  swap25: '關閉',
  swap26: '交易拒絕',
  swap27: '確認交換',
  swap28: '輸出為估值,您將獲得至少{inputOutMin} {symbol},或者交易將被撤',
  swap29: '輸入為估值,您將出售最多{inputInMax} {symbol},或者交易將被撤回',
  swap30: '最小獲得量',
  swap31: '最大出售量',
  swap32: '到',
  swap33: '管理代幣',
  swap34: '管理代幣',
  swap35: '導入',
  swap36: '管理Tokens',
  swap37: '自定義代幣',
  swap38: '全部清除',
  swap39: '搜索名稱或粘貼地址',
  swap40: '設定',
  swap41: '默認交易速度(GWEI)',
  swap42: '標準',
  swap43: '快速',
  swap44: '即時',
  swap46: '輸入有效的滑點百分比',
  swap47: '你的交易可能會失敗',
  swap48: '你的交易可能會被超前',
  swap49: '交易截止時間(分鐘)',
  swap50: '估計',
  swap51: '禁用多跳',
  swap52: '最近交易',
  swap53: '最近沒有交易',
  swap54: '全部清除',
  swap55: '未連接',
  swap56: '加入董事會',
  swap57: '捐贈',
  swap58: '權益贈送',
  swap59: '董事會成員列表',
  swap60: '邀請',
  swap61: '複製',
  swap62: '我的DAPP邀請人:',
  swap63: '邀請記錄',
  swap64: '出錯啦,請稍後再試',
  swap65: '功能暫未開放',
  swap66: '領取',
  swap67: '取出成功',
  swap68: '捐贈中，待確認',
  swap69: '已捐贈',
  swap70: '非白名單用戶',
  swap71: '捐贈無效',
  swap72: '當前餘額不足',
  swap73: '授權中，請稍等',
  swap74: '授權失敗',
  swap75: '捐贈中，請稍等',
  swap76: '捐贈失敗',
  swap77: '兌換',
  swap78: '流動性',
  swap79: '會員',
  swap80: '邀请',
  swap81: '權益池',
  swap82: '資產橋',
  swap83: '池子',
  swap84: '農場',
  swap85: '首頁',
  swap86: '市場',
  swap87: '兌換',
  swap88: '交易',
  swap89: '賺取',
  swap90: '確認',
  swap91: '價格影響',
  swap92: '最小獲得量',
  swap93: '流動性供應商費用',
  swap94: '增加流動性',
  swap95: '增加流動性以接收 LP 代幣',
  swap96: '流動性池中的份額',
  swap97: '兌換率和流動池份額',
  swap98: '您的流動性',
  swap99: '移除流動性以收回代幣',
  swap100: '未找到流動性',
  swap101: '未看到您加入的流動池？',
  swap102: '查看其他LP代幣',
  swap103: '已入池',
  swap104: '移除',
  swap105: '供應',
  swap106: '導入池',
  swap107: '導入現有流動性池',
  swap108: '選擇代幣以查找您的流動性',
  swap109: '您在該流動性池中還沒有提供流動性',
  swap110: '您的錢包中的LP代幣',
  swap111: '接收',
  swap112: '和',
  swap113: '金額',
  swap114: '你將獲得',
  swap115: '輸出為估值。如果價格變化超過 {slippage}%，則您的交易將被撤回',
  swap116: '已焚毀',
  swap117: '已入金',
  swap118: '確認供應',
  swap119: '匯率',
  swap120: '您是第一個流動性供應商。',
  swap121: '您添加代幣的比列，將决定該流動性池的初始價格。',
  swap122: '如果您對匯率滿意，請點擊“供應”以進行檢查。',
  swap123: '正在移除',
  swap124: '正在供應',
  swap125: '確認供應',
  swap126: '白皮書',
  swap127: '稽核',
  swap128: '公告',
  swap129: '提示',
  swap130: 'PHO 1:1兌換',
  swap131: '更多',
  swap132: '正在加載',
  swap133: '信息',
  swap134: '捐贈名額已滿',
  swap135: '我的PHO',
  swap136: '錢包中的PHO',
  swap137: '可收割的PHO',
  swap138: 'PHO價格',
  swap139: 'PHO總量',
  swap140: 'PHO市值',
  swap141: 'PHO信息',
  swap142: '已賺取',
  swap143: '質押',
  swap144: '個人TVL',
  swap145: '社區TVL',
  swap146: '收割',
  swap147: '累計激勵 ',
  swap148: '今日激勵',
  swap149: '參與市商地址數',
  swap150: '我的邀请链接',
  swap151: '最大算力區',
  swap152: '其他算力區',
  swap153: '我的好友邀請',
  swap154: '取消',
  swap155: '修改邀請人',
  swap156: '邀請人不能為空',
  swap157: '修改成功',
  swap158: '質押LP代幣',
  swap159: '贖回LP代幣',
  swap160: '贖回',
  swap161: '獲取',
  swap162: '當前暫無收益',
  swap163: '路由',
  swap164: '審計機構',
  swap165: '合作夥伴',
  swap166: '發行',
  swap167: '活動',
  swap168: '單幣池',
  swap169: 'LP池',
  swap170: '國會',
  swap171: '卡牌',
  swap172: '盲盒',
  swap173: '遊戲',
  swap174: '權益池已分配總額',
  swap175: '待領取數額',
  swap176: '我已獲取數額',
  swap177: '取出',
  swap178: '超級節點',
  swap179: '權益卡已領取份額',
  swap180: '國庫',
  swap181: '國庫累計金額',
  swap182: '國庫已分配金額',
  swap183: '國庫已回購金額',
  swap184: '專家模式',
  swap185: 'D6 Swap是基於OKExChain研發的全網首個股權稅收制Defi平臺，逐步推出Swap、NFT、Gamefi、Lend、機槍池等板塊。 問世以來通過領先的科技和公平公開的市場理念，提供更加廣泛的資產增值方案和前所未有的體驗感，深受廣大用戶喜愛。',
  swap186: '一鍵取出',
  swap187: '價格影響過高',
  swap188: 'FAQ',
  swap189: 'NFT',
  swap190: '賺幣',
  swap191: '進行中',
  swap192: '已結束',

  swap193: '說明',
  swap194: '股權權益生態卡，全球限量1680張',
  swap195: '點亮股權權益生態卡的要求及收益如下',
  swap196: '一星卡：個人地址擁有價值1000USDT流動性，團隊1000USDT流動性，收益共分交易稅收0.1%',
  swap197: '二星卡：個人擁有價值2000USDT流動性，團隊1萬USDT流動性，收益共分交易稅收0.1%',
  swap198: '三星卡：個人3000USDT流動性，團隊10萬USDT流動性，設立工作室，收益共分交易稅收0.1%',
  swap199: '四星卡：個人擁有價值4000USDT流動性，團隊100萬USDT流動性，設立工作室，收益共分交易稅收0.1%',
  swap200: '五星卡：個人5000USDT流動性，團隊1000萬USDT流動性，設立工作室，收益共分交易稅收0.1%',
  swap201: '備註：所有權益生態卡收益全部鏈上自動分配，權益卡享受交易所所有生態分紅，權益卡可傳承可交易可轉讓，滿足要求自動升級，不滿足要求自動降級及自動退出。',
  swap202: '權益卡',
  swap203: '發展路線圖：',
  swap204: '2022年7月2.0版上線',
  swap205: '2022年9月開通持幣多挖',
  swap206: '2022年12月NFT交易平臺上線',
  swap207: '2023年6月Goodbaby遊戲上線',
  swap208: '2023年9月Goodlend上線',
  swap209: 'D6 Swap介紹：',
  swap210: '滑點：',
  swap211: '分配：',
  swap212: '買入',
  swap213: '賣出',
  swap214: '0.5% LP分紅',
  swap215: '0.5%生態權益卡分紅',
  swap216: '0.25%回流底池銷毀LP',
  swap217: '0.25%生態建設',
  swap218: '暫無獎勵領取',
  swap219: '個人TVL',
  swap220: '團隊TVL',
  swap221: 'LP數量',
  swap222: '錢包中的pho',
  swap223: 'GDT',
  swap224: 'GDT空投累計獎勵',
  swap225: '星級用戶累計獎勵',
  swap226: '免手續費倒計時',
  swap227: '可取出獎勵',
  swap228: 'lp挖礦獎勵',
  swap229: '質押pho',
  swap230: '贖回pho',
  swap231: '目標共識',
  swap232: '已達共識',
  swap233: '已共識',
  swap234: '共識',
  swap235: '已解鎖',
  swap236: '可領取',
  swap237: '社區治理',
  swap238: '聯盟共識',
  swap239: '社區聯盟',
  swap240: '質押代幣',
  swap241: '質押中，請稍等',
  swap242: '交易挖礦',
  swap243: '昨日新增',
  swap244: '社區白名單累計獎勵',
  swap245: '股權星卡累計獎勵',
  swap246: 'LP挖礦累計獎勵',
  swap247: '當前可領取',
  swap248: '當前可領取金額',
  swap249: '交易挖礦',
  swap250: '我的分享獎勵累計',
  swap251: '我的交易獎勵累計',
  swap252: '請輸入您的交易雜湊',
  swap253: '校驗',
  swap254: '請輸入您的雜湊',
  swap255: '您的交易地址',
  swap256: '您將獲得',
  swap257: '僅買入GDT及創建流動性的交易可獲得獎勵',
  swap258: '質押GDT-PHO',
  swap259: '贖回GDT-PHO',
  swap260: '全網手續費累計',
  swap261: '待領取分紅',
  swap262: 'GDT聯合股東',
  swap263: '社交',
  swap264: '商城',
  swap265: '精選好物',
  swap266: '優選小店',
  swap267: '即將上線',
  swap268: 'VGT聯創股東',
  swap271: '推薦鏈接',
  swap272: '請綁定領導人',
  swap273: '詳細',
  swap274: '元宇宙',
  swap275: '全局',
  swap276: '交換和流動性',
  swap277: '交易、發展、賺錢、',
  swap278: '多鏈聚合DEX生態平台',
  swap279: '享受最低的交易費，有利可圖的功能和多類型推薦計劃',
  swap280: '路線圖',
  swap281: '2022年7月 2.0版上線',
  swap282: '2022年9月 開通持幣多挖',
  swap283: '2022年12月 NFT交易平台上線',
  swap284: '2023年6月 Goodbaby遊戲上線',
  swap285: '2023年9月 Goodlend上線',
  swap286: '了解更多',
  swap287: '您已持有',
  swap288: '在',
  swap289: '交易加密數字貨幣',
  swap290: '質押流動性和代幣',
  swap291: '賺取分紅獎勵',
  swap292: '發射台',
  swap293: '認證',
  swap294: '優質加密數字資產',
  swap295: '了解',
  swap296: '藝術品',
  swap297: '交易市場',
  swap298: '瀏覽',
  swap299: '即時兌換代幣',
  swap300: '複製成功',
  swap301: '不支持複製，該瀏覽器不支持自動複製',
  swap302: '聯創累計獎勵',
  swap303: '累計分享獎勵',
  swap304: '累計交易獎勵',
  swap305: '資產包',
  swap306: '交易賺幣',
  swap307: '可取出餘額',
  swap308: '請輸入交易雜湊',
  swap309: '確定',
  swap310: '請輸入hash值',
  swap311: '入帳成功',

  swap312:'幣安',
  swap313:'平台',
  swap314:'PHO專區',
  swap315:'GDT專區',
  swap316:'VGT專區',
swap317:'PHO星級卡',
swap318:'個人',
swap319:'團隊',
swap320:'錢包中的',
swap321:'選擇資產包',
swap322:'請選擇',
swap323:'日收益率',
swap324:'收益比例',
swap325:'到期總數',
swap326:'購買',
swap327:'您的錢包餘額',
swap328:'我的資產包',
swap329:'今日總收益',
swap330:'今日靜態收益',
swap331:'今日動態收益',
swap332:'累計收益',
swap333:'到期數量',
swap334:'選擇',
swap335:'加密貨幣專區',
swap336:'購買成功',
swap337:'進入 DApp',
swap338:'圈子',
swap339:'與你的加密圈夥伴一起暢聊',
swap340:'糖果盒',
swap341:'多種優質資產空投',
swap342:'快來免費領取',
swap343:'眾創池',
swap344:'鑄幣中心',
swap345:'社區圈子',
swap346:'糖果盒子',
swap347:'賺幣',
swap348:'空投',
swap349:'分享中心',
swap350:'聯盟計劃',
swap351:"社交",
swap352:"橋",
swap353:"映射",
swap354:"Water 礦池",
swap355:"參與礦池建設，自由賺取高額激勵",
swap356:"流動性礦池",
swap357:"單幣質押池",
swap358:"實時",
swap359:"已完成",
swap360:"僅限已質押",
swap361:"排序依據",
swap362:"搜索",
swap363:" 熱門 ",
swap364:"搜索礦池",
swap365:"這裡什麼也沒有！",
swap366:"首個Water Swap的啟動模板",
swap367:"為每個優質投資者和加密資產找到最佳匹配路徑",
swap368:"創建ISO",
swap369:"成為未來的加密明星",
swap370:"即將到來",
swap371:"請先綁定推薦人",
swap372:"我的邀請人",
swap373:"邀請人地址：",
swap374:"",
swap375:"Q3  推出並上線D6 Swap，並開啟創世鑄幣，同時引入加密社交平台CIRCLE，以深度鏈接社區生態發展。",
swap376:"Q4  對D6進行流動性注入，開啟 “D6共識計劃”，打造D6流通共識，並啟用流動性激勵計劃。推出鏈上聯盟跨境商城，打造“消費增值”生態，推動社區與商業結合新方向。",
swap377:"",
swap378:"Q1 一攬子聯盟扶持計劃，推動D6作為基礎流動性支持，提高市場認可和占比度，上線D-Pay，促進D6 Coin的實體交易流通",
swap379:"Q2 正式上線D6 Trade，開啟CEX和DEX雙交易平台時代，進一步提高D6市場交易流動性，並引入新的伙伴。",
swap380:"",
swap381:"",

d1:"充幣",
d2:"提幣",
d3:"轉賬",
d4:"賬單",
d5:"社區",
d6:"閃兌",
d7:"問題反饋",
d8:"鑄造",
d9:"賺取D6 Coin",
d10:"收益高達",
d11:"進入",
d12:"Water行情",
d13:"今日價格",
d14:"今日收益",
d15:"查看",
d16:"資產",
d17:"隱藏零資產",
d18:"幣種",
d19:"凍結",
d20:"可用",
d21:"選擇通證",
d22:"充值數量",
d23:"請輸入充值數量",
d24:"錢包餘額:",
d25:"入賬地址",
d26:"確定",
d27:"充值記錄",
d28:"提取數量",
d29:"請輸入提取數量",
d30:"賬戶餘額：",
d31:"到賬地址",
d32:"提幣記錄",
d33:"轉賬數量",
d34:"請輸入轉賬數量",
d35:"輸入對方地址",
d36:"請輸入對方地址",
d37:"轉賬記錄",
d38:"資產兌換",
d39:"支付",
d40:"得到",
d41:"數量",
d42:"全部",
d43:"最多可兌換：",
d44:"交易稅：",
d45:"應到賬：",
d46:"支付數量：",
d47:"閃兌記錄",
d48:"確認兌換",
d49:"閃兌記錄",
d50:"已收益",
d51:"待收益",
d52:"燃燒金額",
d53:"請輸入燃燒金額",
d54:"進行中",
d55:"已結束",
d56:"流通進度",
d57:"暫無記錄！",
d58:"歷史收益",
d59:"我的等級",
d60:"當前等級",
d61:"註冊後即可成為 D0用戶",
d62:"好友邀請計劃",
d63:"邀請好友共同鑄幣，開啟Water共識革命，加速賺取獎勵！",
d64:"複製鏈接",
d65:"我的推薦人",
d66:"我的有效推薦",
d67:"地址",
d68:"級別",
d69:"業績",
d70:"下一等級：",
d71:"市場業績",
d72:"尚未完成",
d73:"市場部門",
d74:"您已是最高級別",
d75:"公告",
d76:"綁定確認",
d77:"領導人地址：",
d78:"確認綁定",
d79:"不能為負和零",
d80:"餘額不足",
d81:"充幣成功",
d82:"充幣失敗",
d83:"手續費：",
d84:"轉入",
d85:"轉出",
d86:"賬單記錄",
d87:"時間",
d88:"類型",
d89:"數量",
d90:"幣種",
d91:"請先選擇支付通證",
d92:"此通證禁止雙向兌換",
d93:"不能選擇相同的通證",
d94:"請先綁定領導人",
d95:"剩餘可燃燒金額",
d96:"請先綁定推薦人",
d97:"請填寫推薦人邀請碼",
d98:"最新公告",
d99:"注：",
d100:"2: 提幣訂單，預計為T+1日到賬",
d101:"1：單筆提幣 {min_withdraw} {token_name}起",
d102:"3：每筆提幣收取2 USDT稅收",
d103:"團隊地址",
d104:"團隊有效",
d105:"今日充值",
d106:"今日提取",
d107:"個人",
d108:"團隊",
d109:"開啟後，達到鑄造條件，自動參與鑄造",
d110:"充值後預計1-2分鐘到賬",
d111:"每筆轉賬收取{feerate}%交易稅",
d112:"如有疑問請提交回饋問題 !",
d113:"反饋問題",
d114:"反饋列表",
d115:"反饋內容",
d116:"線上客服",
d117:"暫未回复",
d118:"回复：",
d119:"1.意見反饋",
d120:"2.添加圖片(最多3張)",
d121:"提交",
d122:"請上傳png或jpg格式圖片",
d123:"上傳中，請稍候...",
d124:"文件大小不能超過 10M",
d125:"用戶",
d126:"CIRCLES  AirDrop 礦池",
d127:"發行總量",
d128:"當前產出",
d129:"提取",
d130:"我的餘額",
d131:"查看",
d132:"分享人數",
d133:"工作的礦機",
d134:"時",
d135:"分",
d136:"秒",
d137:"挖礦中",
d138:"啟動挖礦",
d139:"邀請好友",
d140:"好友啟動挖礦",
d141:"獲得分享收益",
d142:"一級分享",
d143:"地址",
d144:"狀態",
d145:"沒有更多了",
d146:"二級分享",
d147:"創世挖礦",
d148:"進入",
d149:"CIRCLES致力於加密社交隱私公鏈建設，計畫將於2025年推出，公鏈代幣總量20億枚，每位用戶可領取1T算力，逐量衰減，累計免費挖出80%即止。",
d150:"總資產估值",
d151:"9·18 通證",
d152:"9·18通證是WateKAM合作的首個以歷史題材社區通證，以「人人持有、永世不忘」為目標，銘記歷史，杜絕淡化，從9·18通證開始 ！即刻免費領取吧！",
d153:"挖礦規則",
d154:"每位使用者可獲得1T算力，每T算力初始24小時產出30枚，每產出1000萬枚算力遞減20%。",
d155:"直接邀請50%,間接邀請25%算力加成激勵！",
d156:"領取空投",
d157:"提幣尚未開放",
d158:"當您綁定市場領袖後，即可領取9.18枚918通證空投。",
d159:"若您參與分享，每分享10個用戶領取空投，您將再獲得一次空投獎勵資格，最高可獲得918枚！",
d160:"我的空投",
d161:"普惠空投",
d162:"我的分享",
d163:"提幣尚未開放",
d164:"當前等級",
d165:"查看收益",
d166:"普通用戶",
d167:"VIP會員",
d168:"升級",
d169:"複製邀請碼",
d170:"地址",
d171:"狀態",
d172:"生態",
d173:"持有卡牌",
d174:"參與生態建設,獲得豐厚獎勵",
d175:"今日可領取",
d176:"C2C交易市場",
d177:"當前等級",
d178:"領取",
d179:"進入",
d180:"您沒有邀請碼",
d181:"我的市場",
d182:"不能購買",
d183:"您的領袖未成為會員",
d184:"鏈上餘額不足",
d185:"平台餘額不足",
d186:"簽名失敗",
d187:"轉帳失敗",
d188:"續費",
d189:"邀請碼",
d190:"你需支付",
d191:"錢包餘額",
d192:"餘額",
d193:"平台餘額",
d194:"付款中...請等待",
d195:"支付成功",
d196:"確定付款",
d197:"關閉",
d198:"支付失敗",
d199:"交易",
d200:"賺幣",
d201:"空投",
d202:"社區",
d203:"生態中心",
d204:'選擇網路',
d205:"枚",
d206:"已領取",
d207:"未領取",


h1:'首頁',
h2:'礦池',
h3:'交易',
h4:'發射台',
h5:'夥伴',
h6:'成為KAM合夥人',
h7:'暢享八大生態權益，快來參與吧！ ',
h8:'進入',
h9:'幣種',
h10:'可用額',
h11:'凍結額',
h12:'在KAM上',
h13:'交易加密數位貨幣',
h14:'參與規則',
h15:'招募規則',
h16:'KAM 合夥人招募,招募 200名創世合夥人,分3期開展,相關規則如下:',
h17:'招募條件：',
h18:'招募期支付',
h19:'獲得規則：',
h20:'1：分享5個有效合夥人自動升級為創世合夥人，先到先得，計劃200席位創世合夥人：先發行一個HAPC代幣，上線前把 HAPC 代幣映射為HAP',
h21:'第一期30個，空投16個/人；',
h22:'第二期60個，空投11個/人；',
h23:'第三期110個，空投6個/人。 ',
h24:'如果情勢好，可以出公告，增加第四期N個（計畫），空投n個/人',
h25:'2：合夥人推薦合夥人可享20%的邀請獎勵',
h26:'3：創世合夥人邀請合夥人可獲得30%邀請激勵 + 10%團隊無限代邀請激勵（平級除外）',
h27:'4、無合夥人邀請鏈接，不能操作充值成為合夥人，也不能推薦合夥人',
h28:'5、系統後端增設：創世合夥人、合夥人的開關設定。 ',
h29:'合夥人招募計畫',
h30:'第一期',
h31:'第{round}期',
h32:'第三期',
h33:'招募進度',
h34:'開始時間：',
h35:'結束時間：',
h36:'招募席位：',
h37:'席位費用：',
h38:'帳戶餘額',
h39:'加入',
h40:'不可加入',
h41:'最新合夥人',
h42:'地址',
h43:'支付金額',
h44:'狀態',
h45:'已完成',
h46:'簽名失敗',
h47:'KAM礦池',
h48:'在KAM礦池,自由賺取高額激勵',
h49:'首個HappyDEX的啟動模板',
h50:'暫無訊息~',
h51:'全新上線',
h52:'交易就來KAM',
h53:'高效率 多收益',
h54:'跨橋鏈',
h55:'普通用戶',
h56:'合夥人',
h57:'創世合夥人',
h58:'問題回饋',
h59:'空投活動',
h60:'NFT 中心',
h61:'生態AI',
h62:'銘文鑄造',
h63:'合夥人權益',
h64:'1: 成為合夥人必須上級也是合夥人',
h65:'2: 合夥人推薦合夥人可以獲得20%推薦獎勵',
h66:'3: 直接分享5個合夥人,可自動升級為「創世合夥人」,最多200個席位,結束為止。 ',
h67:'4: 創世合夥人推薦合夥人可以獲得30%推薦獎勵+ 10%無限代市場獎勵（平級則無）。 ',
h68:'5: 創世合夥人可以獲得HAPB並在上線前映射為HAP,規則如下: ',
h69:'第一期30席,空投15 / 席；',
h70:'第二期60席,空投10 / 席；',
h71:'第三期110席,空投5 / 席。 ',
h72:'6: 合夥人每日可獲得10枚HAPC空投權益。 ',
h73:'溫馨提示:',
h74:'1: 合夥人席次有限,僅招募期間可參與。 ',
h75:'2: 搶購席位前,需確保您的帳戶存在足夠的餘額,需轉入帳戶資產。 ',
h76:'招募介紹',
h77:'Happyswap是一家去中心化現貨合約交易所,為用戶提供安全、高效、的交易體驗。消除價格影響,打破原有的平台壟斷與操控。用戶可以在HappySwap平台進行多種數位資產交易和其他數位資產衍生性商品交易。 HappySwap合夥人全球限量發行,作為合夥人不光可以享受八大合夥人權益,整個平台的盈利跟所有合夥人都有著緊密的關係。數位資產新時代,共同打造真正去中心化交易新玩法,共享數位金融萬倍神話。 ',
h78:'剩餘席位',
h79:'合夥人',
h80:'創世',
h81:'普通',
h82:'合夥',
h83:'本期未開啟',
h84:'加入',
h85:'本期已招滿',
h86:'本期已結束',
h87:'行情',
h88:'邀請',
h89:'資產',
h90:'收益',
h91:'了解',
h92:'KAM訊息',
h93:'今日價格',
h94:'去交易',
h95:'已鑄造',
h96:'去鑄造',
h97:'KAM 發行總量：',
h98:'萬枚',
h99:'KAM計畫銷毀',
h100:'關於KAM',
h101:'KAM ，即KAM（Real-World-Assets） Swap ，是一家致力於推動現實世界資產上鍊，並提供品牌孵化、流通交易、技術支援等服務的去中心化交易平台。 ',
h102:'現實資產代幣化將有助於傳統金融或實體產品面向更廣泛的市場，KAM透過扶持100+資產聯盟，能讓用戶跨地域、跨門檻參與到具有強大前景的領域。 ',
h103:'KAM優勢',
h104:'經驗豐富',
h105:'我們的核心成員來自UNISWAP、MDEX等，具有豐富的去中心化平台經驗！ ',
h106:'現實資產',
h107:'以現實資產為依托，讓您持有的通證更有價值，更加穩健、收益更加可觀！ ',
h108:'安全保障',
h109:'KAM所有合約均開源並進行完善審計，對未開源及審查的通證將拒絕合作！ ',
h110:'聯盟計劃',
h111:'加入俱樂部，提供基於同一市場網體，多種不同機制下收益的可能，成為您穩定可靠的財富夥伴！ ',
h112:'路線圖',
h113:'2022年第二季',
h114:'產品立項及公鏈開發籌備',
h115:'2023年第四季',
h116:'KAM 市場及會員服務開啟',
h117:'2024年第二季',
h118:'KAM推出並招募俱樂部',
h119:'成員',
h120:'2024年第三季',
h121:'打造100+聯盟資產計',
h122:'劃，推動現實資產上鍊服務',
h123:'2025年第一季',
h124:'SocialFi上線，提供多鏈',
h125:'社交隱私錢包服務',
h126:'2025年第二季',
h127:'正式上線公鏈並推',
h128:'動元宇宙網路發展計畫',
h129:'生態中心',
h130:'熱門',
h131:'最新',
h132:'主流',
h133:'敬請期待',
h134:'',
h135:'',
h136:'',
h137:'',
h138:'',
h139:'',
h140:'',
h141:'',
h142:'',
h143:'',
h144:'',
h145:'',
h146:'',
h147:'',
h148:'',
h149:'',
h150:'',
h151:'',
h152:'',
h153:'',
h154:'',
h155:'',
h156:'',
h157:'',
h158:'',
h159:'',

s101:'待產出算力',
s102:'今日算力收益',
s103:'今日分享收益',
s104:'帳單',
s105:'查看',
s106:'個人有效能量',
s107:'最大社區能量',
s108:'其他社區能量',
s109:'龍虎榜',
s110:'能量值：',
s111:'算力值：',
s112:'兌換',
s113:'未開放',
s114:'已產出：',
s115:' 待產出：',
s116:'產出進度',
s117:'你需支付',
s118:'帳戶餘額',
s119:'餘額',
s120:'確定支付',
s121:'NFT商店',
s122:'產出中',
s123:'已耗盡',
s124:'邀請好友',
s125:'購買NFT',
s126:'獲得分享收益',
s127:'地址',
s128:'個人能量',
s129:'團隊能量',
s130:'算力收益',
s131:'已領取',
s132:'待領取',
s133:'領取',
s134:'分享收益',
s135:'龍虎榜',
s136:'時間',
s137:'數量',
s138:'幣種',
s139:'算力帳單',
s140:'分享帳單',
s141:'龍虎榜賬單',
s142:'兌換時間',
s143:'產出中',
s144:'已耗盡',
s145:'NFT中心',

s146:"參與算力",
s147:"獲得收益",
s148:"個人算力",
s149:"團隊算力",
s150:"最大社區算力",
s151:"其他社區算力",
s152:"規則",
s153:"KAM燃燒算力，除創世贈送外，單帳戶最多可鑄造10T，鑄造所使用幣種及數量，均進行燃燒銷毀，以減少流通，每T有效期為365天！",
s154:"有效算力",
s155:"今日社區收益",
s156:"全網持有算力",
s157:"全網分享算力",
s158:"有效連結礦工",
s159:"最大社區算力",
s160:"其他社區算力",
s161:"選擇算力",
s162:"燃燒方式",
s163:"鑄造",
s164:"不可鑄造",
s165:"全部",
s166:"進行中",
s167:"已結束",
s168:"請選擇付款方式",
s169:"不可支付",
s170:"算力激勵",
s171:"社區激勵",
s172:"持幣激勵",
s173:"節點激勵",
s174:"去分享",
s175:"帳戶持有",
s176:"KAM是基於Kademlia為底層協議的WEB 3.0去中心化端到端加密社交多鏈錢包：Kami Wallet的原生平台代幣。",
s177:"KAM總量1.3億枚，初始發行量180萬枚，其餘均透過銷毀產生算力挖礦方式產出，即邊流通、邊發行、邊銷毀，以打造聯盟架構為核心，支持多聯盟幣種組合式銷毀挖礦",
s178:"Kademlia團隊來自Google、Oracle、UNI等團隊核心，擁有豐富的加密社交領域經驗。",
s179:"自由暢聊",
s180:"Kami Wallet採用匿名端對端加密通訊方案，能實現更有效率、更安全、更私密、更便捷的社交需求。",
s181:"長久穩定",
s182:"KAM礦池採用特定算力方案，經驗證明可有效推動持續發展、長久穩定、價值穩步攀升。",
s183:"KAM算力礦池支持聯盟幣種組合式挖礦，推動多邊合作、共同發展、多邊共贏。",
s184:"2024年第一季",
s185:"產品立項及相關工作論證、籌備",
s186:"2024年第三季",
s187:"KAM算力礦池推出級kami",
s188:"Wallet Beta1.0版上線",
s189:"2024年第四季",
s190:"推動多個聯盟合作計劃，",
s191:"實現快速資源整合。",
s192:"2025年第一季",
s193:"推動KAM幅生態建設，",
s194:"完善KAM應用場景網路",
s195:"2025年第二季",
s196:"推動KAM登陸頂級交易",
s197:"平台，進一步實現全球",
s198:"化發展。",
s199:"夥伴",
s200:"算力礦池",
s201:"社區礦工",
s202:"社群節點",
s203:"普通用戶",
s204:"節點",
s205:"礦工",
s206:"資產總估值",
s207:"今日收益",
s208:"資產",
s209:"隱藏零資產",
s210:"幣種",
s211:"可用",
s212:"提現中",
s213:"到期時間",
s214:"流通進度",
s215:"鑄造成功",
s216:"",
s217:"",
s218:"",
s219:"",
s220:"",
s221:"",
s222:"",
s223:"",
s224:"",
s225:"",
s226:"",
};
