<template>
  <div style="background: rgba(16, 20, 32, 1); padding-top: 0px; min-height: 100vh">
    <topbutton router="snowguan" style="margin-bottom: 20px; margin-left: 20px; margin-top: 20px"></topbutton>
    <div class="title" style="padding: 0 20px" v-show="fankuilist.length != 0">
      <p>{{ $t('lang.d114') }}</p>
      <img src="@/assets/imgsnowball/选择.png" alt="" @click="fankui" />
    </div>
    <div class="fankui" style="border-radius: 20px 20px 0 0">
      <div class="c_wlist" v-if="fankuilist.length == 0">
        <img src="@/assets/imgsnowball/fankui_wu.png" alt="" />
        <p>{{ $t('lang.d112') }}</p>
        <button @click="fankui">
          {{ $t('lang.d113') }}
        </button>
      </div>
      <div class="c_list" v-else>
        <div class="l_li" v-for="item in fankuilist" @click="toxiangqin(item)">
          <div style="font-size: 14px; color: #fff">
            {{ item.content }}
          </div>
          <div style="font-size: 12px; color: #999">
            {{ item.created_time }}
          </div>
          <van-icon name="arrow" class="img" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import topbutton from '@/components/topbutton.vue';
import { basic } from '@/api/index';
export default {
  name: '',
  components: {
    topbutton,
  },
  data() {
    return {
      fankuilist: [],
    };
  },
  watch: {
    account() {
      //获取账号
      if (!!this.account) {
        this.init();
      }
    },
    lang() {
      this.init();
    },
  },
  computed: {
    ...mapState(['account', 'lang']),
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let data = {
        page: 1,
        page_size: 10,
      };
      basic.getbasicfeedbacklist(data, this.account).then(res => {
        this.fankuilist = res.data.list || [];
      });
    },
    toxiangqin(item) {
      this.$router.push({ name: 'fanxiangqin', query: { data: item } });
    },
    fankui() {
      // this.$toast(this.$t('lang.swap65'))
      this.$router.push('fankuiadd');
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    color: var(--text-color);
    font-size: 18px;
    font-weight: 700;
    font-family: 'PingFang';
  }
  img {
    width: 24px;
    height: 24px;
  }
}
.fankui {
  width: 100%;

  position: relative;
  background: rgba(16, 20, 32, 1);
  padding-top: 20px;
}
.c_wlist {
  width: 100%;
  // height: 219px;

  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 231px;
    height: 231px;
  }
  p {
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    font-weight: 700;
    font-family: 'PingFang';
  }
  button {
    margin-top: 50px;
    width: 309px;
height: 60px;
    border-radius: 2px;
    border: none;
    color: #000;
    font-size: 14px;
    font-weight: 700;
    font-family: 'PingFang';
    border-radius: 16px;
    background: rgba(178, 87, 247, 1);
  }
}
.c_list {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  padding-top: 0;

  .l_li {
    width: 100%;
    margin-bottom: 20px;
    min-height: 67px;
    border-radius: 20px;
    box-sizing: border-box;

    padding: 15px 20px;
    opacity: 1;
    background: rgba(25, 29, 41, 1);
    border: 1px solid rgba(36, 40, 52, 1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    div {
      white-space: normal;
      word-break: break-word;
      width: 95%;
    }
    .img {
      position: absolute;
      width: 5px;
      height: 10px;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
</style>